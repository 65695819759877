.block-carousel-themes {
    overflow-x: clip;
    overflow-y: visible;

    .inner-content-container {
        position: relative;
        z-index: 1;

        .heading {
            margin-bottom: 30px;
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }

            .title {
                color: color(blue);

                &:not(:last-child) {
                    margin-bottom: 20px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .carousel-themes {
            margin: 0 auto;
            overflow: visible;

            .swiper-slide {
                width: 285px;
            }

            .carousel-themes-navigation {
                display: none;

                @include min(breakpoint(md)) {
                    display: none;
                }

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                    transform: translateY(-50%);
                    transition: background-color $regular-transition;
                    box-shadow: 0px 7px 50px rgba(6, 18, 31, 0.07);
                    background-color: color(white);
                    border: 0;
                    border-radius: 100px;
                    cursor: pointer;
                    width: 60px;
                    height: 40px;

                    svg {
                        @include color-svg(color(black));

                        width: 17px;
                        height: 17px;
                    }

                    &.button-prev {
                        left: -10px;

                        @include min(breakpoint(xl)) {
                            left: -30px;
                        }

                        svg {
                            transform: scaleX(-1);
                        }
                    }

                    &.button-next {
                        right: -10px;

                        @include min(breakpoint(xl)) {
                            right: -30px;
                        }
                    }

                    &:hover {
                        background-color: color(primary);

                        svg {
                            @include color-svg(color(black));
                        }
                    }

                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                }
            }
        }

        .card-theme {
            position: relative;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            border-radius: 50px;
            width: 285px;
            height: 75vh;
            max-height: 643px;
            overflow: hidden;

            .card-theme-link {
                display: flex;

                &:hover {
                    .card-theme-image {
                        transform: translateY(-20%);
                    }

                    .card-theme-wrapper-content {
                        bottom: 0;
                    }
                }

                &.card-theme-link-disable {
                    .card-theme-image {
                        filter: grayscale(1);
                    }
                }

                @media (hover: none) {
                    .card-theme-image {
                        transform: translateY(-20%);
                    }

                    .card-theme-wrapper-content {
                        bottom: 0;
                    }
                }
            }

            .card-theme-image {
                position: absolute;
                top: 0;
                left: 0;
                transition: transform $regular-transition;
                width: 100%;
                height: 100%;
            }

            .card-theme-wrapper-content {
                position: absolute;
                bottom: -50%;
                transition: bottom $regular-transition;
                width: 100%;
                height: 100%;
                
                .card-theme-wrapper-content-inner {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    background-color: color(white);
                    padding: 0 10px 30px;
                    width: 100%;
                    gap: 20px;

                    &::after {
                        display: flex;
                        position: absolute;
                        bottom: 100%;
                        left: 0;
                        background-image: url($path-png + 'nuage-carousel.png');
                        background-size: 100% auto;
                        width: 100%;
                        content: '';
                        aspect-ratio: 440 / 150;
                    }

                    .card-theme-pastille {
                        display: flex;
                        position: relative;
                        z-index: 2;
                        border-radius: 100px;
                        margin-top: -86px;
                        margin-bottom: -15px;
                        width: 82px;
                        height: 82px;
                        overflow: hidden;
                    }

                    .card-theme-name {
                        font-family: $title-font;
                        font-size: rem(33px);
                        text-align: center;
                        text-transform: capitalize;
                    }

                    .card-theme-description {
                        font-size: rem(13px);
                        color: color(black);
                    }

                    .card-soon-available {
                        font-size: rem(20px);
                        color: color(black);
                    }
                }
            }
        }

        .wrapper-buttons {
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            @include min(breakpoint(md)) {
                margin-top: 45px;
            }
        }
    }
}
