.block-carousel-vignettes {
    overflow-x: clip;
    overflow-y: visible;

    .inner-content-container {
        position: relative;
        z-index: 1;

        .heading {
            margin-bottom: 30px;
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }

            .title {
                &:not(:last-child) {
                    margin-bottom: 20px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .carousel-vignettes {
            margin: 0 auto;
            overflow: visible;

            .swiper-wrapper {
                transition-timing-function: linear;
                overflow: visible;

                .swiper-slide {
                    width: 33%;
                    min-width: 300px;
                }
            }

            .vignette {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                position: relative;
                border-radius: 20px;
                padding: 20px;
                width: 100%;
                overflow: hidden;
                text-decoration: none;
                aspect-ratio: 400/400;
                gap: 20px;

                &:hover {
                    .vignette-image {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    .vignette-title {
                        color: color(primary);
                    }
                
                }

                .vignette-image {
                    @include absolute-center-full;
                }

                .vignette-title {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    z-index: 1;
                    transition: color $regular-transition;
                    color: color(white);
                    gap: 25px;

                    .arrow {
                        display: flex;
                    }
                }

                .vignette-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 1;
                    background-color: color(white);
                    border-radius: 10px;
                    width: 50px;
                    height: 50px;
                }
            }

            .vignette-push {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                background-color: color(primary);
                border-radius: 20px;
                padding: 20px;
                width: 100%;
                height: 100%;
                overflow: hidden;
                aspect-ratio: 400/400;
                gap: 40px;

                .vignette-title {
                    position: relative;
                    z-index: 1;
                    color: color(white);
                    text-align: center;
                }

                .btn {
                    position: relative;
                    z-index: 1;
                }

                .pattern {
                    position: absolute;
                    opacity: 0.4;
                    width: 535px;

                    svg {
                        path {
                            fill: color(white);
                        }
                    }

                    &.first {
                        top: -350px;
                        right: -330px;
                    }

                    &.last {
                        bottom: -350px;
                        left: -330px;
                    }
                }
            }

            .carousel-vignettes-pagination {
                display: block;

                @include min(breakpoint(md)) {
                    display: none;
                }
            }

            .carousel-vignettes-navigation {
                display: none;

                @include min(breakpoint(md)) {
                    display: block;
                }

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                    transform: translateY(-50%);
                    transition: background-color $regular-transition;
                    box-shadow: 0px 7px 50px rgba(6, 18, 31, 0.07);
                    background-color: color(white);
                    border: 0;
                    border-radius: 100px;
                    cursor: pointer;
                    width: 60px;
                    height: 40px;

                    svg {
                        @include color-svg(color(black));

                        width: 17px;
                        height: 17px;
                    }

                    &.button-prev {
                        left: -10px;

                        @include min(breakpoint(xl)) {
                            left: -30px;
                        }

                        svg {
                            transform: scaleX(-1);
                        }
                    }

                    &.button-next {
                        right: -10px;

                        @include min(breakpoint(xl)) {
                            right: -30px;
                        }
                    }

                    &:hover {
                        background-color: color(primary);

                        svg {
                            @include color-svg(color(black));
                        }
                    }

                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                }
            }
        }

        .wrapper-buttons {
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            @include min(breakpoint(md)) {
                margin-top: 45px;
            }
        }
    }
}
