.wp-block-woocommerce-checkout {
    position: relative;
    z-index: 3;
    margin: 50px auto 0 !important;
    padding: 0 $container-x-padding !important;
    width: 100%;
    max-width: calc($container-width + ($container-x-padding * 2));

    .wc-block-components-sidebar-layout {
        align-items: flex-start;

        @include max(breakpoint(md)) {
            flex-direction: column;
        }
    }

    // ASIDE 
    .wc-block-components-sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: color(blue);
        background-image: url($path-svg + 'trame-point-light-blue.svg');
        background-size: 760px;
        border: 10px solid #d6eefb;
        border-radius: 60px;
        padding: 20px !important;
        color: color(white);

        @include max(breakpoint(md)) {
            width: 100%;;
        }

        .wp-block-woocommerce-checkout-order-summary-block {
            border: 0;
        }

        .wp-block-woocommerce-checkout-order-summary-block {
            .wc-block-components-totals-wrapper:not(:empty),
            .wp-block-woocommerce-checkout-order-summary-totals-block {
                position: relative;
                border-top: 1px solid color(text-shadow-blue);
                padding: 40px 0 25px;

                &::after {
                    display: flex;
                    position: absolute;
                    top: 0%;
                    right: 0;
                    background-image: url($path-png + 'fanion-panier-dark-blue.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 40%;
                    max-width: 155px;
                    content: '';
                    aspect-ratio: 222/46;
                }

                &:first-child {
                    border: 0;
                    padding-top: 0;

                    &::after {
                        display: none;
                    }
                }
            }

            // SUMMARY
            .wp-block-woocommerce-checkout-order-summary-cart-items-block {
                padding-top: 30px !important;

                .wc-block-components-order-summary {
                    padding: 0;
                }

                .wc-block-components-order-summary__button-text {
                    font-family: $subtitle-font;
                    font-size: rem(30px);
                }

                .wc-block-components-order-summary-item__quantity {
                    box-shadow: unset;
                    background-color: color(text-shadow-blue);
                    border: 0;
                    color: color(white);
                }
            }

            // CODE PROMO
            .wp-block-woocommerce-cart-order-summary-coupon-form-block {
                border: 0 !important;
                
                &::after {
                    display: none !important;
                }
            }

            .wc-block-components-totals-coupon {
                padding: 0;

                .wc-block-components-totals-coupon-link {
                    &:hover {
                        color: color(text-shadow-blue);
                    }
                }

                .wc-block-components-totals-coupon__input {
                    input {
                        background-color: #eef8fd;
                        border: 0;
                        border-radius: 10px 0 0 10px;
                        color: color(black);

                        &:focus {
                            box-shadow: 0 0 0 1px color(text-shadow-blue);
                        }
                    }
                    
                    label {
                        color: color(text-shadow-blue);
                    }

                    & + button {
                        background-color: color(text-shadow-blue);
                        border: 0;
                        border-radius: 0 10px 10px 0;
                        margin: 0;
                        color: color(white);

                        &:disabled {
                            opacity: 0.5;
                        }

                        &:not(:disabled) {
                            cursor: pointer;
                        }
                    }
                }
            }

            // SOUS-TOTAL / REMISE
            .wc-block-components-totals-item {
                padding: 0;

                .wc-block-components-totals-item__label {
                    font-family: $subtitle-font;
                    font-size: rem(30px);
                }

                .wc-block-components-totals-item__value {
                    display: flex;
                    align-items: center;
                    font-family: $main-font-bold;
                }
            }

            .wc-block-components-totals-discount {
                .wc-block-components-totals-item__value {
                    color: color(dark-blue);
                }

                .wc-block-components-totals-discount__coupon-list {
                    .wc-block-components-chip {
                        background-color: color(text-shadow-blue);
                        border: 0;

                        .wc-block-components-chip__remove {
                            background-color: color(white);
                            cursor: pointer;

                            svg {
                                fill: color(text-shadow-blue);
                            }
                        }
                    }
                }
            } 

            // TOTAL
            .wc-block-components-totals-footer-item {
                .wc-block-components-totals-item__label {
                    font-size: rem(40px);
                }

                .wc-block-components-totals-footer-item-tax-value {
                    font-size: rem(35px);
                }
            }
        }
    }

    // MAIN
    .wc-block-components-main {
        --color-cat-checkout: #{color(brown)};
        --color-shadow-cat-checkout: #{color(brown)};

        @include max(breakpoint(md)) {
            padding: 0;
            width: 100%;
        }

        .wc-block-checkout__contact-fields {
            --color-cat-checkout: #{color(blue)};
            --color-shadow-cat-checkout: #{color(text-shadow-blue)};
        }

        .wc-block-checkout__billing-fields {
            --color-cat-checkout: #{color(dark-blue)};
            --color-shadow-cat-checkout: #{color(text-shadow-blue2)};
        }

        .wc-block-checkout__payment-method {
            --color-cat-checkout: #{color(primary)};
            --color-shadow-cat-checkout: #{color(text-shadow-pink)};

            color: color(primary);
        }

        .wc-block-checkout__shipping-option {
            --color-cat-checkout: #{color(dark-blue)};
            --color-shadow-cat-checkout: #{color(text-shadow-blue2)};

            color: color(dark-blue);
        }

        .wc-block-components-title.wc-block-components-title {
            font-size: rem(30px);
            color: var(--color-cat-checkout);
        }

        .wc-block-components-checkout-step--with-step-number {
            .wc-block-components-checkout-step__container {
                &::after {
                    border-color: var(--color-cat-checkout);
                }
            }
        }

        .wc-block-components-form .wc-block-components-text-input input[type=text],
        .wc-block-components-form .wc-block-components-text-input input[type=tel],
        .wc-block-checkout__add-note .wc-block-components-textarea,
        .wc-block-components-combobox .wc-block-components-combobox-control input.components-combobox-control__input {
            border-color: var(--color-cat-checkout);
            color: color(brown);

            & + label {
                color: var(--color-cat-checkout);
            }
        }

        .wc-block-components-combobox {
            label {
                color: var(--color-cat-checkout); 
            }
        }

        .wc-block-components-form .wc-block-components-text-input.has-error {
            input {
                border-color: color(red-alert) !important;
            }

            label {
                color: color(red-alert);
            }
        }

        .wc-block-checkout__actions_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
        }

        .wc-block-components-button:not(.is-link) {
            --btn-color: #{color(white)};
            --btn-hover-color: #{color(white)};
            --btn-background-color: #{color(primary)};
            --btn-background-hover-color: #{color(blue)};

            @extend %btn;

            display: flex;
            border: 0;
            border-radius: $button-radius;
            padding: 5px 20px;
            width: fit-content;
            overflow: hidden;
        }
    }
}
