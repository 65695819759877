.main-footer {
    position: relative;
    background-color: color(secondary);
    overflow-x: clip;
    overflow-y: visible;
    
    @include min(breakpoint(md)) {
        background-image: url($path-png + 'arbrea-footer.png'), url($path-png + 'arbreb-footer.png'), url($path-png + 'arbrec-footer.png'), url($path-png + 'arbred-footer.png');
        background-position: bottom 10% center, bottom left -5px, bottom 9% center, bottom 27% right;
        background-repeat: no-repeat;
        background-size: 67%, 36%, 33%, 29%;
    }

    @include min(breakpoint(xxl)) {
        background-image: url($path-png + 'arbrea-footer.png'), url($path-png + 'arbreb-footer.png'), url($path-png + 'arbred-footer.png');
        background-position: bottom 10% center, bottom left -5px, bottom 27% right;
        background-size: 67%, 36%, 29%;
    }


    .footer-start {
        display: flex;
        position: relative;
        top: -2px;
        z-index: 2;
        background-image: url($path-webp + 'nuageblanc-footer.webp');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        content: '';
        aspect-ratio: 2160 / 315;
    }

    .footer-bottom {
        display: none;

        @include min(breakpoint(md)) {
            display: flex;
            position: absolute;
            right: 0;
            bottom: 0px;
            z-index: 2;
            background-image: url($path-webp + 'footer-bleu.webp');
            background-repeat: no-repeat;
            background-size: contain;
            width: 98%;
            max-width: 1388px;
            content: '';
            aspect-ratio: 2157 / 961;
        }
    }

    .main-footer-content {
        position: relative;
        z-index: 3;
    }

    .main-footer-top {
        position: relative;
        padding: 30px 0 40px;

        .wrapper-newsletter {
            display: flex;
            flex-direction: column;
            align-items: center;

            .image-renard {
                margin-bottom: 20px;
                width: 120px;

                @include min(breakpoint(md)) {
                    margin-bottom: 40px;
                    width: 160px;
                }
            
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .block-newsletter {
            background-color: color(white50);
            border-radius: $border-radius;
            padding: 20px;
            max-width: 670px;

            @include min(breakpoint(md)) {
                padding: 50px;
            }

            p {
                margin: 0;
                font-family: $main-font-bold;
                font-size: rem(23px);
                text-align: center;
                // text-shadow: 1px 1px color(white);
            }

            .tnp-subscription {
                margin-bottom: 0;
                max-width: 100%;

                form {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 20px;

                    @include min(breakpoint(sm)) {
                        flex-direction: row;
                        gap: 30px 20px;
                    }
                }

                .tnp-field {
                    margin: 0;
                }

                .tnp-field-firstname,
                .tnp-field-email {
                    width: 100%;

                    @include min(breakpoint(sm)) {
                        width: calc(50% - 10px);
                    }

                    input {
                        background-color: color(white);
                        border-radius: 10px;
                    }
                }

                .tnp-privacy-field {
                    width: 100%;
                    text-align: center;
                }

                .tnp-field-button {
                    margin: 0 auto;

                    .tnp-submit {
                        transition: background-color $regular-transition;
                        background-color: color(primary);
                        border-radius: $button-radius;
                        cursor: pointer;
                        padding: 5px 20px;
                        min-height: 40px;
                        overflow: hidden;
                        font-family: $main-font;
                        font-size: rem(18px);
                        font-weight: 400;
                        color: color(white);

                        &:hover {
                            background-color: color(blue);
                        }
                    }
                }
            }
        }
    }

    .main-footer-bottom-container {
        position: relative;

        @include max(breakpoint(md)) {
            padding-top: 40px;

            &::before {
                display: flex;
                background-image: url($path-webp + 'footer-bleu-mobile.webp');
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: contain;
                width: 100%;
                content: '';
                aspect-ratio: 2160/1068;
            }
        }

        &::after {
            display: flex;
            position: absolute;
            bottom: 70%;
            left: 50%;
            z-index: -1;
            transform: translateX(-50%);
            background-image: url($path-webp + 'arbrea-footer.webp');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: contain;
            width: 120%;
            content: '';
            aspect-ratio: 1445/1764;
            
            @include min(breakpoint(sm)) {
                bottom: 60%;
                width: 70%;
            }

            @include min(breakpoint(md)) {
                display: none;
            }
        }
    }

    .main-footer-middle {
        position: relative;
        z-index: 2;
        background-color: color(light-blue);
        margin-top: 0;
        padding-top: 40px;

        @include min(breakpoint(md)) {
            background-color: transparent;
            background-image: unset;
            margin-top: 250px;
            padding-top: 0;
        }

        @include min(breakpoint(l)) {
            margin-top: 410px;
        }
    }
    
    .main-footer-primary-menu {
        @include min(breakpoint(md)) {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-end;
            width: auto;
            gap: 50px;
        }

        @include min(breakpoint(l)) {
            gap: 100px;
        }

        .menu {
            &:not(:last-child) {
                margin-bottom: 30px;

                @include min(breakpoint(md)) {
                    margin: 0;
                }
            }

            .col-menu-content {
                font-size: rem(16px);

                &.col-menu-content--adress {
                    line-height: 2.2;
                }

                .col-menu-content-links {
                    @extend %reset-ul;

                    .link {
                        @include min(breakpoint(md)) {
                            text-align: right;
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        .main-link {
                            font-family: $main-font-bold;
                            font-size: rem(18px);
                            color: inherit;
                            color: color(brown);
                            text-decoration: none;

                            &:hover {
                                color: color(primary);
                                text-decoration: underline;
                                text-shadow: 0 0 transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .main-footer-bottom {
        position: relative;
        background-color: color(light-blue);
        padding-top: 30px;
        padding-bottom: 30px;
        gap: 15px;

        @include min(breakpoint(md)) {
            background-color: transparent;
            background-image: unset;
            margin-top: -105px;
            padding-bottom: 40px;
            gap: 40px;
        }

        @include min(breakpoint(l)) {
            margin-top: -65px;
        }

        .container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            @include min(breakpoint(md)) {
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
            }
        }

        .wrapper-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
        }
    }

    .copyright {
        font-family: $main-font-bold;
        font-size: rem(16px);
        color: color(brown);
        text-align: right;
    }

    .main-footer-secondary-menu {
        .secondary-menu {
            @extend %reset-ul;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;

            @include min(breakpoint(md)) {
                align-items: center;
                gap: 40px;
            }

            .secondary-menu-link {
                font-size: rem(14px);
                color: color(white);
                text-decoration: none;
                text-shadow: 1px 1px color(text-shadow-blue);

                &:hover {
                    color: color(primary);
                    text-decoration: underline;
                    text-shadow: 1px 1px color(text-shadow-pink2);
                }
            }
        }
    }

    .social-instragram-wrapper {
        flex-shrink: 0;
        position: relative;
        background-image: url($path-webp + 'fond-logo-insta.webp');
        background-repeat: no-repeat;
        background-size: contain;
        width: 90px;
        aspect-ratio: 140 / 190;

        @include min(breakpoint(md)) {
            width: 140px;
        }
    
        .instagram {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            position: absolute;
            bottom: 22px;
            left: 19px;
            width: 45px;
            height: 45px;

            @include min(breakpoint(md)) {
                bottom: 38px;
                left: 29px;
                width: 65px;
                height: 65px;
            }

            svg {
                width: 100%;
                height: 100%;

                path {
                    transition: fill $regular-transition;
                }

                .insta1 {
                    fill: #88cee1;
                }

                .insta2 {
                    fill: #b5e0e4;
                }
            }

            &:hover {
                svg {
                    .insta1 {
                        fill: color(primary);
                    }

                    .insta2 {
                        fill: color(secondary);
                    }
                }
            }
        }
    }
}
