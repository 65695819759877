.post-card {
    margin: 0 auto;
    width: 100%;
    height: 100%;

    .card-link {
        display: block;
        background: transparent;
        border: 0;
        width: 100%;
        color: inherit;
        text-align: left;
        text-decoration: none;

        &:not(.inactive) {
            cursor: pointer;

            &:hover {
                .card-thumbnail {
                    img {
                        transform: scale(1.1);
                    }
                }

                .card-title {
                    color: color(primary);
                }
            }
        }
    }

    .card-thumbnail {
        position: relative;
        border-radius: 20px;
        margin-bottom: 25px;
        aspect-ratio: 300/190;
    }

    .card-post-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        gap: 15px;
    }

    .card-date {
        opacity: 0.5;
        font-size: rem(12px);
        font-weight: 300;
        line-height: 170%;
        color: color(black);
    }

    .card-tag {
        background-color: color(secondary);
    }

    .card-title {
        transition: color $regular-transition;
        font-size: rem(18px);
        font-weight: 400;
        line-height: 1.2;
        color: color(black);

        @include min(breakpoint(md)) {
            font-size: rem(20px);
        }
    }

    .card-categories-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .card-excerpt {
        margin-top: 20px;
        font-size: rem(14px);
        font-weight: 300;
        line-height: 1.5;
        color: color(black);

        @include min(breakpoint(md)) {
            flex-grow: 1;
            margin-top: 30px;
            font-size: rem(16px);
        }
    }

    .wrapper-buttons {
        margin-top: 20px;
    }
 
    // CARD HIGHLIGHT
    &.card-sticky {
        position: relative;
        z-index: 2;
        box-shadow: 0px 4px 70px 0px rgba(0, 0, 0, 0.08);
        background-color: color(white);
        border-radius: 30px;
        padding: 20px;
        max-width: 100%;

        .card-link {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                gap: 70px;
            }
        }

        .card-thumbnail {
            margin: 0;

            @include min(breakpoint(md)) {
                width: 50%;
                max-width: 600px;
                aspect-ratio: 600/390;
            }
        }

        .card-wrapper-text {
            @include min(breakpoint(md)) {
                display: flex;
                flex-direction: column;
                padding: 20px 0;
                width: 50%;
                max-width: 510px;
            }

            .card-title {
                font-size: rem(32px);
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: -0.96px;
            }
        }
    }
}
