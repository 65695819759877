.pagination {
    margin-top: 40px;

    @include min(breakpoint(md)) {
        margin-top: 60px;
    }

    .pagination-list {
        @extend %reset-ul;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .page-numbers {
            display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
            border-radius: 100px;
            width: 50px;
            height: 50px;
            font-size: rem(18px);
            font-weight: 400;
            color: color(black);
            text-decoration: none;

            &.current,
            &:hover {
                background-color: color(black);
                color: color(white);
            }
            
            &.next, 
            &.prev {
                svg {
                    @include color-svg(color(black));
                }

                &:hover {
                    svg {
                        @include color-svg(color(white));
                    }
                }
            }

            &.prev {
                svg {
                    transform: scaleX(-1);
                }
            }
        }
    }
}
