.archive-product-cat {
    .heading-list-product {
        margin-bottom: 20px;
        text-align: center;

        @include min(breakpoint(md)) {
            margin-bottom: 50px;
        }
    }

    .result-product {
        margin-top: 30px;
    }
}
