.block-etapes-illustrees {
    position: relative;
    overflow: hidden;

    .block-inner-content-container {
        .title {
            margin-bottom: 40px;
            color: color(white);
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }
        }

        .wrapper-steps {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
                gap: 40px;
            }

            .step {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                color: color(white);

                @include min(breakpoint(sm)) {
                    width: calc(100% / 2 - (40px * 1 / 2));
                }

                @include min(breakpoint(lg)) {
                    width: calc(100% / 4 - (40px * 3 / 4));
                }

                .step-image {
                    display: flex;
                    margin-bottom: 30px;
                    width: auto;
                    height: 140px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 50px;
                        height: 190px;

                        img {
                            width: auto;
                            height: 100%;
                        }
                    }

                    img {
                        object-fit: contain;
                    }
                }

                .step-title {
                    margin-bottom: 10px;
                    font-family: $subtitle-font;
                    font-size: rem(23px);
                    font-weight: 400;
                    line-height: 1.1;
                    letter-spacing: -0.4px;
                    color: color(white);
                    text-align: center;
                }

                .step-text {
                    font-size: rem(18px);
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
    }
}

