.card-article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .wrapper-image {
        border-radius: 15px;
        margin-bottom: 20px;
        width: 100%;
        aspect-ratio: 380/250;
    }

    .title {
        transition: color $regular-transition;
        margin-bottom: 10px;
        font-size: rem(18px);
        font-weight: 500;
        line-height: 1.5;
    }

    .text {
        flex-grow: 1;
        font-size: rem(14px);
        font-weight: 300;
    }

    .btn {
        margin-top: 25px;
    }
}
