.page-404 {
    display: flex;
    position: relative;
    min-height: calc(80vh);
    overflow: hidden;

    .container {
        display: flex;
        flex-direction: column;
    }

    .page-404-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        height: 100%;

        .title {
            margin-bottom: 30px;
            font-size: rem(80px);
            font-weight: 400;
            line-height: 1;
            color: color(primary);

            @include min(breakpoint(md)) {
                font-size: rem(120px);
            }
        }

        .text {
            margin-bottom: 50px;
            font-size: rem(25px);
            font-weight: 400;
            line-height: 1.6;
            text-align: center;

            @include min(breakpoint(md)) {
                font-size: rem(32px);
            }
        }
    }
}
