.woocommerce-order {
    margin: 50px auto 0;
    padding: 0 $container-x-padding;
    width: 100%;
    max-width: calc($container-width + ($container-x-padding * 2));

    .woocommerce-thankyou-order-received {
        @extend %h2;

        margin-bottom: 30px;
        color: color(blue);
        text-align: center;
        text-shadow: 2px 2px color(text-shadow-blue);
    }

    .woocommerce-order-overview.woocommerce-thankyou-order-details.order_details {
        margin: 0 auto;
        margin: 0 auto 3em;
        padding: 0;
        width: fit-content;

        li {
            margin-bottom: 20px;
        }
    }

    .woocommerce-order-details {
        .woocommerce-order-details__title {
            color: color(dark-blue);
            text-shadow: 2px 2px color(text-shadow-blue2);
        }
    }

    .addresses {
        .woocommerce-column__title {
            color: color(primary);
            text-shadow: 2px 2px color(text-shadow-pink);
        }
    }

    .order-again {
        text-align: center;

        .button {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(primary)};
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(blue)};

            @extend %btn;

            position: relative;
            border: 0;
            border-radius: $button-radius;
            padding: 5px 20px;
            min-height: 40px;
            overflow: hidden;
        }
    }
}
