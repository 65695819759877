.block-image-pleine-largeur {
    position: relative;
    background-color: color(blue);
    overflow: hidden;

    &.no-image {
        @include min(breakpoint(lg)) {
            &::before,
            &::after {
                display: block;
                position: absolute;
                content: '';
            }

            &::before {
                top: -70px;
                left: -40px;
                transform: rotate(-15deg);
                background-image: url($path-webp + 'decoration-banderole.webp');
                background-size: contain;
                width: 360px;
                aspect-ratio: 1 / 1;
            }
    
            &::after {
                right: -70px;
                bottom: -50px;
                background-image: url($path-webp + 'decoration-fete.webp');
                background-size: contain;
                width: 340px;
                aspect-ratio: 1 / 1;
            }
        }
    }

    .inner-content-container {
        .wrapper-image {
            aspect-ratio: 375/250;

            @include min(breakpoint(md)) {
                width: 100%;
                aspect-ratio: unset;
            }

            &.bg-image {
                @include absolute-center-full;
                
                z-index: -1;
                aspect-ratio: unset;

                @include min(breakpoint(md)) {
                    min-height: unset;
                }
            }
        }

        .container {
            position: relative;
            margin: 0 auto;
            padding: 40px;
            max-width: 980px;

            &::before,
            &::after {
                @include absolute-center-full;

                display: flex;
                content: '';
            }

       /*      &::before {
                background-color: color(white30);
                border-radius: 30px;
            }

            &::after {
                opacity: 0.8;
                background-color: color(dark-blue);
                border-radius: 20px;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
            } */

            .text {
                position: relative;
                z-index: 1;
                font-size: rem(20px);
                color: color(white);
                text-align: center;

                @include min(breakpoint(md)) {
                    font-size: rem(25px);
                }
            }

            .wrapper-buttons {
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 1;
                margin-top: 30px;
            }
        }
    }
}
