.main-header {
    --color-logo: #{color(dark-grey)};
    --bg-color-menu: #{color(white80)};
    --color-text-menu: #{color(white)};
    --bg-color-text-btn-link: #{color(black)};
    --color-text-btn-link: #{color(white)};
    --bottom-submenu-desktop: 110px;
    --bottom-submenu-desktop-sticky: 55px;

    display: flex;
    align-items: center;
    position: fixed;
    top: var(--admin-bar-height);
    left: 0;
    z-index: 1000;
    transition: $transition;
    padding: 10px 0;
    width: 100%;
    height: $height-nav-primary-mobile;

    @include min(breakpoint(nav)) {
        top: calc(var(--admin-bar-height));
        border: 0;
        padding: 0;
        height: $height-nav-primary;
    }

    .main-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: rem(16px);
        gap: 10px;

        .header-nav-link {
            display: flex;
            transition: $transition;
            font-size: rem(24px);
            font-weight: 400;
            text-decoration: none;

            &:not(.display-button) {
                &:hover {
                    opacity: 0.5;
                }
            }

            &.display-button {
                display: flex;
                align-items: center;
                justify-content: center !important;
                position: relative;
                transition: border-color $regular-transition, background-color $regular-transition;
                background-color: transparent;
                border: 1px solid var(--color-text-menu);
                border-radius: 100px;
                padding: 5px 13px;
                min-height: 40px;
                overflow: hidden;
                font-size: rem(16px);
                color: var(--color-text-menu);

                &:hover {
                    background-color: color(primary);
                    border-color: color(primary);
                    color: color(black);
                }
            }
        }

        .main-header-logo {
            display: flex;
            flex-shrink: 0;
            position: relative;
            z-index: 3;
            transition: width $regular-transition;
            width: 210px;
            height: auto;

            @include max(breakpoint(nav)) {
                width: 115px;
                height: auto;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .main-header-left {
            display: flex;
            align-items: center;
            transition: padding $regular-transition, background-color $regular-transition;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 60px;
            padding: 12px 20px 12px 30px;
            gap: 40px;

            @include max(breakpoint(nav)) {
                background-color: transparent;
                gap: 14px;
            }

            .burger-btn,
            .back-button-menu {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 3;
                background: none;
                border: 0;
                cursor: pointer;
                color: inherit;
                appearance: button;

                @include min(breakpoint(nav)) {
                    display: none;
                }
            }

            .burger-btn {
                position: relative;
                background-color: color(white);
                border-radius: 100px;
                padding: 0;
                width: 40px;
                height: 40px;

                span,
                &::before,
                &::after {
                    display: block;
                    position: absolute;
                    transition: $transition;
                    background-color: color(primary);
                    border-radius: 20px;
                    width: 20px;
                    height: 2px;
                    content: '';
                }

                span {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &::before {
                    top: 12px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                &::after {
                    bottom: 12px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                .main-header-menu-is-active & {
                    span {
                        opacity: 0;
                    }

                    &::before {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
    
                    &::after {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }

            .main-header-menu {
                display: flex;
                align-items: center;
                gap: 30px;

                // Mobile
                @include max(breakpoint(nav)) {
                    flex-direction: column;
                    align-items: flex-start;
                    position: absolute;
                    top: var(--admin-bar-height);
                    left: -100%;
                    transition: $transition;
                    background-color: color(light-brown);
                    padding: 0;
                    width: 100%;
                    height: calc(var(--vh, 100vh) - var(--admin-bar-height));
                    overflow: hidden;
                    gap: 0;

                    &--active {
                        left: 0;
                    }

                    .header-nav-list {
                        flex-direction: column;
                        flex-grow: 1;
                        align-items: unset !important;
                        z-index: 1;
                        margin: 0 auto;
                        margin-top: calc(30px + #{$height-nav-primary-mobile});
                        padding: 20px;
                        width: 100%;
                        overflow: scroll;
                        gap: 20px !important;

                        .header-nav-element {
                            border-bottom: 1px solid color(white50);
                            padding-bottom: 20px;

                            .header-nav-link {
                                justify-content: space-between;
                                width: 100%;
                                font-size: rem(25px);

                                &::after {
                                    transform: rotate(-45deg) !important;
                                }

                                &.display-button {
                                    margin-top: 30px;
                                }
                            }

                            &.has-submenu--active {
                                .submenu-wrapper {
                                    left: 0;
                                }
                            }
                        }
                    }

                    .submenu-wrapper {
                        position: absolute;
                        top: 0;
                        left: -100%;
                        z-index: 2;
                        transition: left $regular-transition;
                        background-color: color(primary);
                        padding-top: calc(30px + #{$height-nav-primary-mobile});
                        width: 100%;
                        height: calc(var(--vh, 100vh) - var(--admin-bar-height));
                        overflow: auto;

                        .submenu-inner-container {
                            padding: 0 20px 20px;
                            height: calc(100% - 80px);
                            overflow: auto;
                        }

                        .submenu-inner-wrapper {
                            .back-button-menu {
                                display: flex;
                                align-items: center;
                                transition: opacity $regular-transition;
                                margin: 10px 0 30px;
                                padding-left: 0;
                                width: fit-content;
                                font-size: rem(25px);
                                font-weight: 400;
                                color: var(--color-text-menu);
                                text-shadow: 1px 2px color(text-shadow-blue);
                                gap: 13px;

                                .icon-arrow-left {
                                    display: flex;
                                    align-items: center;

                                    svg {
                                        @include color-svg(var(--color-text-menu));
                                    }
                                }

                                @media(hover: hover) {
                                    &:hover {
                                        opacity: 0.5;
                                    }
                                }
                            }

                            .col-simple {
                                .submenu-item {
                                    &:not(:last-child) {
                                        margin-bottom: 30px;
                                    }

                                    .submenu-nav-link {
                                        font-size: rem(20px);
                                        font-weight: 400;
                                        color: var(--color-text-menu);
                                        text-decoration: none;
                                        text-shadow: 1px 2px color(text-shadow-blue);

                                        .submenu-nav-link-text {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .col-push {
                                display: none;
                            }
                        }
                    }
                }

                .header-nav-list {
                    @extend %reset-ul;

                    display: flex;
                    align-items: center;
                    gap: 40px;

                    .header-nav-element {
                        cursor: pointer;

                        &:nth-child(1) {
                            .header-nav-link {
                                color: color(yellow);
                            }
                        }

                        &:nth-child(2) {
                            .header-nav-link {
                                color: color(primary);
                            }
                        }

                        &:nth-child(3) {
                            .header-nav-link {
                                color: color(blue);
                            }
                        }

                        &:nth-child(4) {
                            .header-nav-link {
                                color: color(light-blue);
                            }
                        }

                        &:nth-child(5) {
                            .header-nav-link {
                                color: color(secondary);
                            }
                        }

                        .header-nav-link {
                            cursor: pointer;
                        }

                        &:hover {
                            .submenu-wrapper {
                                @include min(breakpoint(nav)) {
                                    display: block !important;
                                    visibility: visible !important;
                                    opacity: 1 !important;
                                }

                                .submenu-inner-wrapper {
                                    pointer-events: auto !important;
                                }
                            }
                        }

                        &.has-submenu {
                            .header-nav-link {
                                display: flex;
                                align-items: center;
                                background: transparent;
                                border: 0;
                                padding: 0;
                                height: 20px;
                                gap: 7px;
                                appearance: none;

                                &::after {
                                    display: flex;
                                    transform: rotate(45deg);
                                    box-shadow: 2px 1px var(--cat-color-shadow, color(text-shadow-pink));
                                    border-right: 2px solid currentColor;
                                    border-bottom: 2px solid currentColor;
                                    width: 6px;
                                    height: 6px;
                                    content: '';
                                }

                                &.active {
                                    & + .submenu-wrapper {
                                        @include min(breakpoint(nav)) {
                                            display: block !important;
                                            visibility: visible !important;
                                            opacity: 1 !important;
                                        }

                                        .submenu-inner-wrapper {
                                            pointer-events: auto !important;
                                        }
                                    }
                                }
                            }

                            .submenu-wrapper {
                                // SUBMENU DESKTOP
                                @include min(breakpoint(nav)) {
                                    position: absolute;
                                    bottom: var(--bottom-submenu-desktop);
                                    left: 50%;
                                    transform: translateX(-50%) translateY(100%) !important;
                                    transition: opacity $regular-transition, visibility $regular-transition;
                                    visibility: hidden;
                                    opacity: 0;
                                    cursor: auto;
                                    width: 100%;
                                    max-width: 1240px;
                                    pointer-events: none;

                                    &::before {
                                        display: block;
                                        width: 100%;
                                        height: 34px;
                                        content: '';
                                        pointer-events: auto;
                                    }

                                    .submenu-inner-container {
                                        margin: 0 auto;
                                    }

                                    .submenu-inner-wrapper {
                                        display: flex;
                                        box-shadow: 0px 7px 50px rgba(6, 18, 31, 0.07);
                                        background-color: color(white);
                                        border-radius: 20px;
                                        margin: 0 auto;
                                        width: fit-content;
                                        max-width: 100%;
                                        max-height: calc(100vh - (20px + $height-nav-primary));
                                        pointer-events: none;

                                        .back-button-menu {
                                            display: none;
                                        }

                                        .col {
                                            &.col-simple {
                                                display: grid;
                                                grid-template-columns: 1fr 1fr;
                                                column-gap: 20px;
                                                padding: 30px;

                                                @include max(breakpoint(xl)) {
                                                    width: 100%;
                                                }

                                                .submenu-nav-link {
                                                    display: flex;
                                                    flex-direction: column;
                                                    cursor: pointer;
                                                    padding: 20px;
                                                    max-width: 320px;
                                                    color: color(black);
                                                    text-decoration: none;
                                                    gap: 10px;

                                                    &:hover {
                                                        color: color(primary);
                                                    }

                                                    .submenu-nav-link-title {
                                                        font-size: rem(18px);
                                                        line-height: 1.1; 
                                                    }

                                                    .submenu-nav-link-text {
                                                        font-size: rem(16px);
                                                        font-weight: 200;
                                                        line-height: 1.4;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .col-push {
                                    flex-shrink: 0;
                                    border: 5px solid color(white);
                                    border-radius: 20px;
                                    margin: 0 auto;
                                    width: 340px;
                                    overflow: hidden;

                                    .push-link {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: space-between;
                                        position: relative;
                                        border-radius: 20px;
                                        padding: 30px;
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                        text-decoration: none;
                                        gap: 20px;

                                        &:hover {
                                            .wrapper-image {
                                                img {
                                                    scale: 1.1;
                                                }
                                            }
                                            
                                            .title {
                                                color: color(primary);
                                            }
                                        }
                                    }

                                    .wrapper-image {
                                        @include absolute-center-full;
                                    }

                                    .icon {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: relative;
                                        z-index: 1;
                                        background-color: color(white);
                                        border-radius: 10px;
                                        width: 50px;
                                        height: 50px;
                                    }

                                    .title {
                                        display: flex;
                                        flex-direction: column;
                                        position: relative;
                                        z-index: 1;
                                        transition: color $regular-transition;
                                        font-size: rem(32px);
                                        font-weight: 400;
                                        font-style: normal;
                                        line-height: 1;
                                        color: color(white);
                                        gap: 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                .social-networks-wrapper {
                    display: flex;
                    align-items: center;
                    z-index: 1;
                    margin: 0;
                    padding: 20px 20px 30px;
                    list-style: none;
                    gap: 10px;

                    @include min(breakpoint(nav)) {
                        display: none;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;

                        svg {
                            transition: opacity $regular-transition;

                            .insta1 {
                                fill: transparent;
                            }
            
                            .insta2 {
                                fill: color(white);
                            }
                        }

                        &:hover {
                            svg {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        .main-header-right {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 3;
            gap: 15px;

            .button-link {
                display: flex;
                align-items: center;
                position: relative;
                transition: color $regular-transition, background-color $regular-transition;
                background-color: var(--bg-color-text-btn-link);
                border-radius: 100px;
                padding: 5px 13px;
                min-height: 40px;
                overflow: hidden;
                font-size: rem(16px);
                color: var(--color-text-btn-link);
                text-align: center;
                text-decoration: none;

                @include max(breakpoint(nav)) {
                    padding: 5px 10px;
                    min-height: 28px;
                    font-size: rem(12px);
                }

                &:hover {
                    background-color: color(primary);
                    color: color(black);
                }
            }

            .cart {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                transition: background-color $regular-transition;
                background-color: var(--cat-color-shadow, color(primary));
                border-radius: 100px;
                width: 40px;
                height: 40px;
                text-decoration: none;

                &:hover {
                    background-color: color(dark-blue);

                    .cart-count {
                        border-color: color(dark-blue);
                        color: color(dark-blue);
                    }
                }
                
                .icon-cart {
                    display: flex;
                    width: 70%;

                    svg {
                        @include color-svg(color(white));

                        width: 100%;
                        height: auto;
                    }
                }

                .cart-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    transition: border-color $regular-transition, color $regular-transition;
                    background-color: color(white);
                    border: 1px solid var(--cat-color-shadow, color(primary));
                    border-radius: 100px;
                    width: 20px;
                    height: 20px;
                    font-size: rem(10px);
                    font-weight: 700;
                    color: var(--cat-color-shadow, color(primary));
                    text-align: center;
                }
            }
        }
    }

    &.main-header-sticky {
        --bottom-submenu-desktop: 32px;

        top: 0;
        box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.08);
        background-color: var(--bg-color-menu);
        height: $height-nav-primary-sticky;
        backdrop-filter: blur(10px);

        @include min(breakpoint(nav)) {
            top: var(--admin-bar-height);

            .main-header-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: rem(16px);
                gap: 10px;
        
                .header-nav-link {
                    font-size: rem(20px);
                    color: color(brown);
                    text-shadow: unset;

                    &::after {
                        box-shadow: unset !important;
                    }
                }

                .main-header-menu {
                    display: flex;
                    align-items: center;
                    gap: 30px;
    
                    .header-nav-list {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .main-header-logo {
            width: 115px;
        }

        .main-header-left {
            background-color: transparent;
            padding: 0;
        }
    }

    .header-nav-link {
        .header-nav-element.has-submenu {
            pointer-events: none !important;
        }
    }

    &.header-mobile {
        .header-nav-element.has-submenu {
            .header-nav-link {
                pointer-events: all !important;
            }
        }
    }
}

.bandeau-push-navigation {
    background-color: color(blue);
    padding: 10px 0;
    font-size: rem(18px);
    font-weight: 400;
    text-align: center;
}
