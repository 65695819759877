.header-archive-product-cat {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0 !important;
    overflow-x: clip;
    overflow-y: visible;

    .background-image {
        display: none;

        @include min(breakpoint(md)) {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            background-position: bottom left -320px;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
        }

        @include min(breakpoint(lg)) {
            background-position: bottom left -150px;
        }

        @include min(breakpoint(xl)) {
            background-position: bottom left -100px;;
        }
        
    }

    .container {
        z-index: 2;
    }

    .inner-content-container {
        display: flex;
        justify-content: flex-end;
    }

    .heading {
        position: relative;
        z-index: 2;
        padding-bottom: 40px;

        @include min(breakpoint(md)) {
            padding-top: 260px;
            padding-bottom: 0;
            max-width: 40%;
        }

        & > .title {
            color: color(white);

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        & > .text {
            font-size: rem(28px);
            color: color(white);

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        & > .wrapper-button {
            margin-top: 30px;
        }
    }

    .header-end {
        display: flex;
        position: relative;
        top: 2px;
        z-index: 3;
        background-image: url($path-webp + 'part-nuage.webp');
        background-size: contain;
        width: 100%;
        content: '';
        aspect-ratio: 2160 / 278;

        .pastille-cat {
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 13%;
            max-width: 170px;
            height: auto;

            @include min(breakpoint(md)) {
                top: 50px; 
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
