.up-sells {
    background-color: var(--cat-color-text, color(pink-title));

    h2 {
        margin-bottom: 50px;
        color: color(white);
        text-align: center;
        text-shadow: 4px 2px var(--cat-color-shadow, color(text-shadow-pink2));
    }
}

.related {
    h2 {
        margin-bottom: 50px;
        color: var(--cat-color-text, color(primary));
        text-align: center;
        text-shadow: 4px 2px var(--cat-color-shadow, color(text-shadow-pink2));
    }
}
