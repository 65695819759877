.block-carousel-produits {
    overflow-x: clip;
    overflow-y: visible;

    .inner-content-container {
        position: relative;
        z-index: 1;

        .heading {
            margin-bottom: 30px;
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }

            .title {
                color: color(blue);

                &:not(:last-child) {
                    margin-bottom: 20px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .carousel-produits {
            margin: 0 auto;
            overflow: visible;

            .swiper-wrapper {
                transition-timing-function: linear;
                overflow: visible;

                .swiper-slide {
                    width: 295px;
                    height: unset;
                }
            }

            .product {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                float: unset !important;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
                background-color: color(white);
                border-radius: 50px;
                margin: 0 !important;
                padding: 0 0 30px !important;
                width: 295px;
                height: 100%;
                overflow: hidden;
                text-decoration: none;

                .product-content {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    align-items: center;
                    padding: 0 10px;
                }

                &.outofstock {
                    .wrapper-image {
                        filter: grayscale(1);
                    }
                }
            
                .product-image {
                    position: relative;
                    margin: 0;
                    
                    &::after {
                        display: flex;
                        position: absolute;
                        bottom: -30px;
                        left: 0;
                        background-image: url($path-png + 'nuage-carousel.png');
                        background-size: 100% auto;
                        width: 100%;
                        content: '';
                        aspect-ratio: 440 / 150;
                    }
                }

                .product-pastille-cat {
                    margin-top: -50px;
                    margin-bottom: 13px;
                    width: 26%;
                }
            
                .product-title {
                    position: relative;
                    margin-bottom: 20px;
                    padding: 0;
                    font-family: $title-font;
                    font-size: rem(28px) !important;
                    color: color(blue);
                    text-align: center;
                }

                .product-price {
                    flex-grow: 1;
                    margin-bottom: 20px;
                    font-size: rem(20px) !important;
                    color: color(primary) !important;
                    text-align: center;
                }
            }

            .carousel-produits-pagination {
                display: block;
            }
        }

        .wrapper-buttons {
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            @include min(breakpoint(md)) {
                margin-top: 45px;
            }
        }
    }
}
