.single-product {
    .header-single-product {
        &.product-type-variable {
            .summary-wrapper {
                .summary {
                    .price {
                        display: none;
                    }
                }
            }
        }

        &.product-type-simple {
            .ppom-price-item {
                display: none !important;
            }
        }

        .summary-wrapper {
            position: relative;
            background-color: var(--cat-color-text, var(--color-secondary));
            background-image: var(--url-bg-decoration-product-left);
            background-position: bottom left;
            background-repeat: no-repeat;
            background-size: 75%;
            margin-top: 0;
            padding-top: calc($height-nav-primary-mobile + 32px);
            padding-bottom: 0 !important;
            overflow-x: clip;
            overflow-y: visible;
        
            @include min(breakpoint(nav)) {
                background-image: var(--url-bg-decoration-product-left), var(--url-bg-decoration-product-right);
                background-position: bottom left, bottom right;
                background-size: 40%, 40%;
                padding-top: calc($spacing-top-bottom-desktop + $height-nav-primary - 80px);
            }

            .wrapper-product-infos {
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;
                position: relative;
                z-index: 2;
                margin-bottom: 30px;
                gap: 30px;

                @include min(breakpoint(md)) {
                    flex-direction: row;
                    align-items: flex-start;
                }
            }

            .woocommerce-product-gallery {
                position: relative;
                float: none;
                margin: 0;

                @include min(breakpoint(md)) {
                    flex-direction: row;
                    align-items: flex-start;
                    margin-top: 75px;
                }

                &.woocommerce-product-gallery--with-images {
                    & > .woocommerce-product-gallery__wrapper,
                    & > .flex-viewport {
                        border: 10px solid color(white30);
                        border-radius: 40px;
                        overflow: hidden;
                    }
                }

                .flex-control-thumbs {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: 30px;
                    gap: 20px;

                    li {
                        float: unset;
                        border: 3px solid color(white30);
                        border-radius: 200px;
                        width: calc((100% / 4) - ((20px * 3) / 4));
                        max-width: 150px;
                        overflow: hidden;

                        @include min(breakpoint(md)) {
                            border: 10px solid color(white30);
                        }
                    }
                }

                .flex-direction-nav {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    .flex-nav-prev .flex-prev,
                    .flex-nav-next .flex-next {
                        display: block;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 25%;
                        transition: background-color $regular-transition;
                        background-color: color(white50);
                        border: 0;
                        border-radius: 150px;
                        cursor: pointer;
                        width: 60px;
                        height: 60px;
                        text-indent: -9999px;
                        pointer-events: auto;
                        
                        &::before {
                            display: flex;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: width $regular-transition, height $regular-transition;
                            background-color: var(--cat-color-shadow, color(brown));
                            border-radius: 150px;
                            width: calc(100% - 15px);
                            height: calc(100% - 15px);
                            content: '';
                        }

                        &::after {
                            display: flex;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-60%, -50%) rotate(-45deg);
                            border-right: 4px solid color(white);
                            border-bottom: 4px solid color(white);
                            border-radius: 3px;
                            width: 14px;
                            height: 14px;
                            content: '';
                        }

                        &:hover {
                            &::before {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &.flex-disabled {
                            display: none;
                        }
                    }

                    .flex-nav-prev .flex-prev {
                        left: -20px;

                        &::after {
                            transform: translate(-40%, -50%) rotate(-225deg);
                        }
                    }

                    .flex-nav-next .flex-next {
                        right: -20px;
                    }
                }
            }

            .summary {
                position: relative;
                float: none;
                background-color: color(white);
                border-radius: 0 0 20px 20px;
                margin: 60px 0 80px;
                padding: 20px;
                color: var(--cat-color-shadow, color(primary));

                @include min(breakpoint(md)) {
                    margin-top: 100px;
                }

                &::before {
                    display: flex;
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    background-image: url($path-webp + 'nuage-fiche-produit.webp');
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: calc(100%);
                    content: '';
                    aspect-ratio: 925/203;
                }

                .pastille-cat {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    bottom: calc(100% - 10px);
                    left: 50%;
                    transform: translateX(-36%);
                    width: 19%;
                }

                .entry-title {
                    margin-bottom: 30px;
                    font-size: rem(35px);
                    color: var(--cat-color-text, color(primary));
                    text-align: center;
                    text-shadow: 2px 2px var(--cat-color-shadow, color(primary));
                }

                .price {
                    position: absolute;
                    bottom: 60px;
                    left: 20px;
                    border: 0;
                    margin: 0;
                    font-size: rem(25px);
                    font-weight: 700;
                    color: color(primary);

                    @include min(breakpoint(md)) {
                        bottom: 40px;
                        left: 40px;
                        font-size: rem(50px);
                    }
                }
                
                .woocommerce-product-details__short-description {
                    font-size: rem(18px);
                    color: color(brown);

                    ul {
                        color: var(--cat-color-shadow, color(brown));
                    }
                }

                .cart {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin: 40px 0;

                    @include min(breakpoint(md)) {
                        flex-direction: row;
                        gap: 20px;
                    }

                    &::before {
                        display: none;
                    }

                    .variations {
                        display: block;
                        margin-bottom: 5px;

                        tbody {
                            display: block;
                        }

                        tr {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            &:not(:last-child) {
                                margin-bottom: 15px;
                            }

                            .label {
                                margin-bottom: 0.5rem;
                                font-family: $main-font;
                                font-size: rem(20px);
                                line-height: normal;
                                color: var(--cat-color-text, color(primary));
                                text-align: left;

                                @include min(breakpoint(md)) {
                                    font-family: $subtitle-font;
                                    font-size: rem(23px);
                                }
                            }

                            .value {
                                display: flex;
                                flex-direction: column;
                                width: 100%;

                                select {
                                    background-image: url($path-png + 'arrow-select.png'), linear-gradient(to left, var(--cat-color-text, color(primary)), var(--cat-color-text, color(primary)) 40px, color(white) 40px);
                                    background-position: center right 10px, center right;
                                    background-repeat: no-repeat;
                                    background-size: 18px, 100%;
                                    border: 1px solid var(--cat-color-text, color(primary));
                                    border-radius: 3px;
                                    margin: 0;
                                    padding: 0 45px 0 15px;
                                    width: 100%;
                                    height: 35px;
                                    color: var(--cat-color-shadow, color(primary));
                                    appearance: none;
                                }
                            }

                            .reset_variations {
                                color: var(--cat-color-shadow, color(primary));
                            }
                        }
                    }

                    /* stylelint-disable selector-max-id, declaration-no-important */
                    #ppom-price-container {
                        display: flex !important;
                    }
                    /* stylelint-enable selector-max-id, declaration-no-important */

                    &.ppom-flex-controller {
                        .ppom-wrapper {
                            margin-bottom: 0 !important;
                        }
                    }

                    .woocommerce-variation-add-to-cart,
                    &.ppom-flex-controller {
                        .ppom-wrapper {
                            margin-bottom: 30px;

                            @include min(breakpoint(md)) {
                                margin-bottom: 70px;
                            }

                            span.show_description {
                                display: block;
                                font-family: $main-font;
                                font-size: rem(16px);
                                color: var(--cat-color-shadow, color(primary));
                                text-shadow: none;
                            }
                        }

                        .ppom-rendering-fields {
                            margin: 0;
                            padding: 0;
                        }

                        .ppom-col {
                            padding: 0 !important;
                        }

                        .form-group {
                            .form-control-label {
                                margin-bottom: 0.5rem;
                                font-family: $main-font;
                                font-size: rem(20px);
                                line-height: normal;
                                color: var(--cat-color-text, color(primary));
                                text-align: left;

                                @include min(breakpoint(md)) {
                                    font-family: $subtitle-font;
                                    font-size: rem(23px);
                                }

                                .show_required {
                                    color: var(--cat-color-text, color(primary));
                                }
                            }

                            .ppom-input {
                                border: 1px solid var(--cat-color-text, color(primary));
                                border-radius: 3px;
                                margin: 0;
                                padding: 0 15px;
                                width: 100%;
                                height: 35px;
                                color: var(--cat-color-shadow, color(primary));
                                appearance: none;
                            }

                            .form-select {
                                background-image: url($path-png + 'arrow-select.png'), linear-gradient(to left, var(--cat-color-text, color(primary)), var(--cat-color-text, color(primary)) 40px, color(white) 40px);
                                background-position: center right 10px, center right;
                                background-repeat: no-repeat;
                                background-size: 18px, 100%;
                                border: 1px solid var(--cat-color-text, color(primary));
                                border-radius: 3px;
                                margin: 0;
                                padding: 0 45px 0 15px;
                                width: 100%;
                                height: 35px;
                                color: var(--cat-color-shadow, color(primary));
                                appearance: none;
                            }
                        }

                        .ppom-product-base-price {
                            display: none;
                        }
                    
                        .ppom-total-without-fixed {
                            .ppom-label-item {
                                display: none;
                            }
                    
                            .ppom-price-item {
                                position: absolute;
                                bottom: 50px;
                                left: 10px;
                                border: 0;
                                font-size: rem(25px);
                                color: color(primary);

                                @include min(breakpoint(md)) {
                                    bottom: 40px;
                                    left: 40px;
                                    font-size: rem(50px);
                                }
                            }
                        }
                    }

                    .quantity {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        float: unset;
                        margin: 0;
                        padding: 0;
                        gap: 5px;

                        @include min(breakpoint(md)) {
                            padding: 0 40px;
                        }

                        &::before {
                            margin-right: 5px;
                            color: color(brown);
                            content: 'Quantité';
                        }

                        input {
                            background-color: var(--cat-color-text, color(primary));
                            border: 0;
                            border-radius: 150px;
                            padding-left: 15px;
                            height: 40px;
                            color: color(white);
                            text-align: center;
                        }
                    }

                    button[type=submit] {
                        position: absolute;
                        bottom: -80px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: var(--cat-color-shadow, color(primary));
                        border-radius: 100px;
                        width: 100%;
                        font-size: 25px;

                        &.disabled {
                            opacity: 1 !important;
                            background-color: color(grey);
                        }

                        &:not(.disabled) {
                            &:hover {
                                background-color: var(--cat-color-text, color(blue));
                            }
                        }
                    }
                }

                .product_meta {
                    display: none;
                }
            }
        }

        .images-decoration {
            display: flex;
            position: absolute;
    
            &.banderole {
                display: block;
                top: 0;
                left: -61px;
                z-index: 0;
                transform: rotate(-25deg);
                background-image: url($path-webp + 'banderole-blanche.webp');
                background-repeat: no-repeat;
                background-size: contain;
                width: 75%;
                max-width: 490px;
                aspect-ratio: 522 / 272;
            }
    
            &.confettis {
                top: 140px;
                right: -30px;
                z-index: 0;
                width: 35%;
                max-width: 390px;
                aspect-ratio: 253 / 144;
    
                @include min(breakpoint(md)) {
                    top: 120px;
                    right: -30px;
                }
            }
            
            svg {
                @include color-svg(#fbf8f6);

                width: 100%;
                height: auto;
            }
        }

        // CAS PARTICULIER DINO
        &.product_cat-jurassic-cute {
            .woocommerce-tabs .tabs li::after {
                background-color: color(orange-dino) !important;
            }

            .woocommerce-tabs ul.tabs::before {
                border-color: color(orange-dino) !important;
                
            }

            .summary-wrapper .summary .woocommerce-product-details__short-description ul,
            .summary-wrapper .summary .cart .variations tr .value select,
            .summary-wrapper .summary .cart .woocommerce-variation-add-to-cart .form-group .ppom-input {
                color: color(orange-dino) !important;
            }
        }
    }

    .product-single-header-end {
        display: flex;
        position: relative;
        position: relative;
        top: 2px;
        left: 50%;
        z-index: 3;
        transform: translateX(-50%);
        background-image: url($path-webp + 'part-nuage.webp');
        background-repeat: no-repeat;
        background-size: contain;
        width: var(--vw, 100vw);
        content: '';
        aspect-ratio: 2160 / 278;
    }

    .woocommerce-tabs {
        margin-top: -40px;

        .tabs {
            margin: 0 0 80px !important;
            padding: 0 !important;
            overflow: visible !important;

            &::before {
                left: 50% !important;
                transform: translateX(-50%);
                border-color: var(--cat-color-shadow, color(primary)) !important;
                width: var(--vw, 100vw) !important;
            }

            &::after {
                display: flex !important;
                position: absolute;
                top: calc(100% - 1px);
                left: 50% !important;
                transform: translateX(-50%);
                background-image: var(--url-bg-decoration-product-tabs);
                background-position: top center;
                background-repeat: no-repeat;
                background-size: contain;
                width: var(--vw, 100vw) !important;
                height: 45px;

                @include min(breakpoint(md)) {
                    background-image: var(--url-bg-decoration-product-tabs), var(--url-bg-decoration-product-tabs);
                    background-position: top left, top right;
                }
            }

            li {
                position: relative !important;
                background-color: transparent !important;
                border: 0 !important;
                padding: 0 20px !important;
                width: 100%;
                color: var(--cat-color-shadow, color(primary)) !important;
                text-align: center;

                @include min(breakpoint(md)) {
                    padding: 0 75px !important;
                    width: auto;
                }

                &::before {
                    display: none;
                }

                &:not(:last-child) {
                    &::before {
                        display: none;
                        position: absolute;
                        top: 50%;
                        right: -12px;
                        left: unset !important;
                        transform: translateY(-50%);
                        box-shadow: unset !important;
                        background-color: var(--cat-color-text, color(primary));
                        border-radius: 100px !important;
                        content: '';

                        @include min(breakpoint(md)) {
                            display: flex;
                            width: 16px !important;
                            height: 16px !important;
                        }

                        @include min(breakpoint(lg)) {
                            width: 24px !important;
                            height: 24px !important;
                        }
                    }
                }

                &::after {
                    position: absolute;
                    right: unset !important;
                    bottom: 0px !important;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: width $regular-transition;
                    box-shadow: unset !important;
                    background-color: var(--cat-color-shadow, color(primary));
                    border: 0 !important;
                    border-radius: 0 !important;
                    width: 0 !important;
                    height: 4px !important;
                    content: '';

                    @include min(breakpoint(md)) {
                        height: 10px !important;  
                    }
                }

                &.active {
                    background-color: transparent !important;
                    border-bottom-color: transparent !important;

                    &::after {
                        width: 80% !important;
                    }
                }

                &::before,
                &::after {
                    border-color: var(--cat-color-text, color(primary)) !important;;
                }

                a {
                    font-family: $subtitle-font;
                    font-size: rem(25px);
                    font-weight: 400 !important;
                    color: var(--cat-color-shadow, color(primary)) !important;

                    @include min(breakpoint(md)) {
                        font-size: rem(25px);
                    }

                    @include min(breakpoint(lg)) {
                        font-size: rem(27px);
                    }
                }
            }
        }

        .panel {
            border: 0;
            padding: 0 !important;
            color: color(brown);

            h2 {
                display: none;
                margin-bottom: 20px;
                color: var(--cat-color-text, color(pink-title));
                text-shadow: 4px 2px var(--cat-color-shadow, color(text-shadow-pink2));
            }

            table {
                /* stylelint-disable selector-class-pattern */
                &.shop_attributes {
                    color: var(--cat-color-shadow, color(brown));
                }
                /* stylelint-enable selector-class-pattern */
            }
        }
    }
}

//// CAS PARTICULIER CATEGORIE DINO

