.header-simple {
    position: relative;
    background-color: color(secondary);
    background-image: url($path-png + 'header-accueil-arbre-a.png'), url($path-png + 'header-accueil-arbre-b.png');
    background-position: bottom left, bottom right;
    background-repeat: no-repeat;
    background-size: 36%, 45%;
    padding-bottom: 0 !important;
    overflow-x: clip;
    overflow-y: visible;

    .inner-content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 1;
        padding-bottom: 50px;
    }

    .breadcrumb {
        justify-content: flex-start;
        width: 100%;
        max-width: 680px;
        color: color(white);
    } 

    .wrapper-content {
        position: relative;
        z-index: 3;
        width: 100%;
        max-width: 680px;

        & > .title {
            color: color(white);
            text-shadow: 2px 2px color(text-shadow-pink2);

            &:not(:last-child) {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 30px;
                }
            }
        }

        & > .text {
            font-size: rem(20px);
            line-height: 1.2;
            color: color(white);

            @include min(breakpoint(md)) {
                font-size: rem(24px);
            }
        }

        .wrapper-buttons {
            margin-top: 30px;

            @include min(breakpoint(md)) {
                margin-top: 40px;
            }
        }
    }

    .header-accueil-end {
        display: flex;
        position: relative;
        top: 2px;
        z-index: 3;
        background-image: url($path-webp + 'part-nuage.webp');
        background-size: contain;
        width: 100%;
        content: '';
        aspect-ratio: 2160 / 278;
    }
}
