.btn, .button, %btn {
    --btn-color: #{color(black)};
    --btn-hover-color: #{color(primary)};
    --btn-background-color: transparent;
    --btn-background-hover-color: transparent;
    --btn-border-color: transparent;
    --btn-border-hover-color: transparent;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-duration: $regular-transition-duration;
    transition-property: color, background-color, border-color, gap, transform;
    transition-timing-function: ease-out;
    background-color: var(--btn-background-color);
    border: 2px solid transparent;
    border-color: var(--btn-border-color);
    cursor: pointer;
    font-family: $main-font;
    font-size: rem(18px);
    font-weight: 400;
    line-height: 1;
    color: var(--btn-color);
    text-align: center;
    text-decoration: none;
    gap: 10px;

    @include min(breakpoint(md)) {
        font-size: rem(20px);
    }

    @media (hover: hover) {
        &:hover {
            background-color: var(--btn-background-hover-color);
            border-color: var(--btn-border-hover-color);
            color: var(--btn-hover-color);

            .arrow {
                svg {
                    @include color-svg(var(--btn-hover-color));
                }
            }
        }
    }

    .btn-text {
        position: relative;
        z-index: 1;
    }

    .arrow {
        svg {
            @include color-svg(var(--btn-color));
        }
    }

    &.btn-simple {
        text-decoration: underline;
        text-underline-offset: rem(3px);

        &.btn-primary {
            --btn-color: #{color(primary)};
        }

        &.btn-primary-hover {
            --btn-hover-color: #{color(primary)};
        }

        &.btn-white {
            --btn-color: #{color(white)};
        }

        &.btn-white-hover {
            --btn-hover-color: #{color(white)};
        }

        &.btn-black {
            --btn-color: #{color(black)};
        }

        &.btn-black-hover {
            --btn-hover-color: #{color(black)};
        }

        &.btn-blue {
            --btn-color: #{color(blue)};
        }

        &.btn-blue-hover {
            --btn-hover-color: #{color(blue)};
        }

        &.btn-brown {
            --btn-color: #{color(brown)};
        }

        &.btn-brown-hover {
            --btn-hover-color: #{color(brown)};
        }

        &.btn-yellow {
            --btn-color: #{color(yellow)};
        }

        &.btn-yellow-hover {
            --btn-hover-color: #{color(yellow)};
        }
    }

    &.btn-filled {
        position: relative;
        border: 0;
        border-radius: $button-radius;
        padding: 5px 20px;
        min-height: 40px;
        overflow: hidden;

        &.btn-primary {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(primary)};
        }

        &.btn-primary-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(primary)};
        }

        &.btn-white {
            --btn-color: #{color(primary)};
            --btn-background-color: #{color(white)};
        }

        &.btn-white-hover {
            --btn-hover-color: #{color(primary)};
            --btn-background-hover-color: #{color(white)};
        }

        &.btn-black {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(black)};
        }
        
        &.btn-black-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(black)};
        }

        &.btn-blue {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(blue)};
        }

        &.btn-blue-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(blue)};
        }

        &.btn-brown {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(brown)};
        }

        &.btn-brown-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(brown)};
        }

        &.btn-yellow {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(yellow)};
        }

        &.btn-yellow-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(yellow)};
        }
    }

    &.btn-outlined {
        position: relative;
        border-radius: $button-radius;
        padding: 5px 20px;
        min-height: 40px;
        overflow: hidden;

        &.btn-primary {
            --btn-color: #{color(primary)};
            --btn-background-color: transparent;
            --btn-border-color: #{color(primary)};
        }

        &.btn-primary-hover {
            --btn-color-hover: #{color(white)};
            --btn-background-hover-color: #{color(primary)};
            --btn-border-hover-color: #{color(primary)};
        }

        &.btn-white {
            --btn-color: #{color(white)};
            --btn-background-color: transparent;
            --btn-border-color: #{color(white)};
        }

        &.btn-white-hover {
            --btn-color-hover: #{color(primary)};
            --btn-background-hover-color: #{color(white)};
            --btn-border-hover-color: #{color(white)};
        }

        &.btn-black {
            --btn-color: #{color(black)};
            --btn-background-color: transparent;
            --btn-border-color: #{color(black)};
        }

        &.btn-black-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(black)};
            --btn-border-hover-color: #{color(black)};
        }

        &.btn-blue {
            --btn-color: #{color(blue)};
            --btn-background-color: transparent;
            --btn-border-color: #{color(blue)};
        }

        &.btn-blue-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(blue)};
            --btn-border-hover-color: #{color(blue)};
        }

        &.btn-brown {
            --btn-color: #{color(brown)};
            --btn-background-color: transparent;
            --btn-border-color: #{color(brown)};
        }

        &.btn-brown-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(brown)};
            --btn-border-hover-color: #{color(brown)};
        }

        &.btn-yellow {
            --btn-color: #{color(yellow)};
            --btn-background-color: transparent;
            --btn-border-color: #{color(yellow)};
        }

        &.btn-yellow-hover {
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(yellow)};
            --btn-border-hover-color: #{color(yellow)};
        }
    }

    > * {
        pointer-events: none;
    }
}

.wrapper-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.horizontal {
        align-items: flex-start;

        @include min(breakpoint(md)) {
            flex-direction: row;
        }
    }
}
