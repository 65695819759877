.block-liste-produits {
    position: relative;
    overflow-x: clip;
    overflow-y: visible;

    &.has-transparent-bg-color {
        --block-color-title: #{color(pink-title)};
        --block-color-product: #{color(pink-title)};
        --block-color-price: #{color(blue)};

        .single-product & {
            --block-color-title: #{color(blue)};
            --block-color-product: #{color(blue)};
            --block-color-price: #{color(primary)};
        }
    }

    &.has-light-brown-bg-color {
        --block-color-title: #{color(brown)};
        --block-color-product: #{color(yellow)};
        --block-color-price: #{color(blue)};

    }

    .inner-content-container {
        position: relative;
        z-index: 1;

        .heading {
            margin-bottom: 40px;
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 60px;
            }

            .title {
                color: var(--block-color-title);
            }
        }

        .liste-produits {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            overflow: visible;
            gap: 45px 60px;

            .product {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                max-width: 380px;
                text-decoration: none;

                @include min(breakpoint(md)) {
                    width: calc((100% / 2) - (60px / 2 * 2));
                }

                @include min(breakpoint(lg)) {
                    width: calc((100% / 3) - (60px / 3 * 2));
                }

                &:hover {
                    .product-image {
                        & > img {
                            transform: scale(1.1);
                        }
                    }
                }

                &.boxed {
                    .product-content {
                       background-color: color(white);
                       border-radius: 0 0 30px 30px;
                       padding: 20px;
                    }

                    .product-image {
                        position: relative;
                        border-radius: 30px 30px 0 0;
                        margin-bottom: 0;

                        &::after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background-image: url($path-webp + 'separation-produit.webp');
                            background-position: bottom center;
                            background-repeat: no-repeat;
                            background-size: 101% auto;
                            width: 100%;
                            content: '';
                            aspect-ratio: 567 / 37;
                        }
                    }

                    .product-button {
                        bottom: 40px !important;
                    }
                }

                .product-content {
                    text-align: center;
                }
            
                .product-image {
                    position: relative;
                    border-radius: 30px;
                    margin-bottom: 15px;

                    img {
                        transition: transform $regular-transition;
                    }
                    
                    .product-button {
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .product-pastille-cat {
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        background-color: color(white);
                        border: 2px solid color(white);
                        border-radius: 100px;
                        width: 70px;
                        height: 70px;
                        overflow: hidden;
                    }
                }
            
                .product-title {
                    position: relative;
                    z-index: 1;
                    padding: 0;
                    font-family: $subtitle-font;
                    font-size: rem(28px);
                    color: var(--block-color-product);
                }

                .product-cat {
                    position: relative;
                    z-index: 1;
                    margin-bottom: 10px;
                    color: var(--block-color-shadow);
                }

                .product-price {
                    flex-grow: 1;
                    position: relative;
                    z-index: 1;
                    font-size: rem(20px);
                    color: var(--block-color-price);
                }
            }
        }
    }
}
