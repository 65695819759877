
.formulaire {
    p {
        margin: 0;
    }

    // reset
    input, select, textarea {
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        appearance: none;
    }

    .row {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    
        &.row-col-2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                gap: 32px;

                & > div {
                    width: calc(50% - 16px);
                }
            }
        }

        &.submit {
            text-align: right;
        }
    }

    .field {
        --color-field: #{color(brown)};

        width: 100%;

        &.primary {
            --color-field: #{color(primary)};
        }

        &.blue {
            --color-field: #{color(blue)};
        }

        &.dark-blue {
            --color-field: #{color(dark-blue)};
        }

        &.yellow {
            --color-field: #{color(yellow)};
        }

        label {
            display: inline-block;
            margin-bottom: 5px;
            font-family: $subtitle-font;
            font-size: rem(24px);
            font-weight: 400;
            line-height: 1;
            color: var(--color-field);
        }

        input:not([type='checkbox']), select, textarea {
            background-color: color(white);
            border: 1px solid var(--color-field);
            border-radius: 5px;
            padding: 0 15px;
            height: 40px;
            font-size: rem(18px);
            font-weight: 300;

            &::placeholder {
                color: color(black50);
            }

            &[aria-invalid='true'] {
                border-color: color(red-alert);
            }

            /* &:-internal-autofill-selected {
                box-shadow: 0 0 0 30px rgb(133, 159, 248) inset !important;
                -webkit-text-fill-color: white !important;
                border-color: rgb(154, 177, 255);
            } */
        }

        select {
            background-image: url($path-svg + 'arrow-down-select.svg');
            background-position: center right 15px;
            background-repeat: no-repeat;
            padding-right: 50px;
        }

        input[type=date] {
            &::-webkit-datetime-edit {
                color: color(black);
            }

            &::-webkit-calendar-picker-indicator {
                display: none;
                opacity: 0.5;
                background-image: url($path-svg + 'icon-calendar.svg');
            }
        }

        textarea {
            padding: 15px;
            height: auto;
            min-height: 160px;
        }

        .field-input-radio-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            .field-input-radio {
                position: relative;

                input[type=radio] {
                    position: absolute;
                    width: 0;
                    height: 0;
                }

                .label-radio {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: border-color $regular-transition, background-color $regular-transition, color $regular-transition;
                    border: 1px solid color(light-grey);
                    border-radius: 8px;
                    cursor: pointer;
                    width: 65px;
                    height: 50px;
                    font-size: rem(18px);
                    color: color(dark-grey);
    
                    &:hover {
                        background-color: color(primary);
                        border-color: color(primary);
                        color: color(black);
                    }
                }

                input[type=radio]:checked + .label-radio {
                    background-color: color(primary);
                    border-color: color(primary);
                    color: color(black);
                }
            }
        }

        &.checkbox-custom {
            .wpcf7-checkbox {
                display: block;
                
                .wpcf7-list-item {
                    margin: 0;

                    label {
                        display: flex;
                        align-items: flex-start;
                        gap: 10px;
                        font-family: $main-font;
                    }

                    input[type=checkbox] {
                        position: relative;
                        border: 1px solid color(brown);
                        border-radius: 3px;
                        cursor: pointer;
                        width: 16px;
                        height: 16px;

                        &::before {
                            @include absolute-center;

                            transform: translate(-50%, -50%) scale(0);
                            transition: transform $regular-transition;
                            background-color: color(primary);
                            border-radius: 2px;
                            width: 9px;
                            content: '';
                            aspect-ratio: 1/1;
                        }

                        &:checked {
                            &::before {
                                transform: translate(-50%, -50%) scale(1);
                            }
                        }
                    }

                    .wpcf7-list-item-label {
                        cursor: pointer;
                        font-size: rem(16px);
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .optin {
        font-size: rem(14px);
        color: color(primary);

        p {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        a {
            font-weight: 500;
        }
    }

    .wrapper-submit {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        gap: 32px;

        @include min(breakpoint(md)) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 56px;
        }

    }

    form[data-status='sent'] {
        .row,
        .optin,
        .wrapper-submit {
            display: none;
        }
    }

    .wpcf7-response-output {
        background-color: color(blue);
        border-radius: 20px;
        font-size: rem(25px);
        color: color(white);
        color: color(white);
        text-align: center;

        &:not(:empty) {
            padding: 25px;
        }
    }

    .wpcf7-not-valid-tip {
        display: inline-block;
        margin-top: 5px;
        font-size: 11px;
        color: color(red-alert);
    }

    .screen-reader-response {
        display: none;
    }
}
