.woocommerce {
    .woocommerce-result-count {
        margin-bottom: 40px;
    }

    .woocommerce-ordering {
        display: none;
    }

    ul.products {
        --gap-products: 30px;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--gap-products);
        clear: both;

        @include min(breakpoint(md)) {
            margin-top: 50px;
        }

        &::before,
        &::after {
            display: none;
        }

        .product {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            float: unset !important;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            background-color: color(white);
            border-radius: 50px;
            margin: 0 !important;
            padding: 0 0 30px !important;
            width: 100% !important;
            max-width: 293px;
            overflow: hidden;

            @include min(breakpoint(sm)) {
                width: calc(100% / 2 - (var(--gap-products) * 1 / 2)) !important;
            }

            @include min(breakpoint(lg)) {
                width: calc(100% / 4 - (var(--gap-products) * 3 / 4)) !important;
            }

            &.outofstock {
                .wrapper-image {
                    filter: grayscale(1);
                }
            }
        
            .wrapper-image {
                position: relative;
                margin: 0 -10px;
                
                &::after {
                    display: flex;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    background-image: url($path-png + 'nuage-carousel.png');
                    background-size: 100% auto;
                    width: 100%;
                    content: '';
                    aspect-ratio: 440 / 150;
                }
            }
        
            .woocommerce-loop-product__title {
                position: relative;
                margin-bottom: 30px;
                padding: 0;
                font-family: $title-font;
                font-size: rem(32px) !important;
                color: var(--cat-color-text);
                text-align: center;
            }

            .price {
                font-size: rem(28px) !important;
                color: color(primary) !important;
                text-align: center;
            }

            .button {
                display: flex !important;
                align-items: center;
                background-color: var(--cat-color-shadow, color(blue));
                border-radius: 150px;
                margin: 0 auto;
                padding: 5px 20px;
                min-height: 40px;
                font-size: rem(20px);
                color: color(white);

                &:hover {
                    background-color: var(--cat-color-text, color(primary));
                }
            }

            .wc-forward {
                text-align: center;
                text-decoration: underline;
            }

            .onsale {
                top: 50px !important;
                right: -22% !important;
                transform: rotate(45deg);
                background-color: color(primary);
                border-radius: 0;
                width: 100%;
            }
        }
    }
}

.woocommerce-page {
    ul.products .product .woocommerce-loop-product__title {
        position: relative;
        margin-bottom: 20px !important;
        padding: 0 10px !important;

        &::before {
            display: flex;
            position: absolute;
            bottom: 100%;
            left: 0;
            background-image: url($path-png + 'nuage-carousel.png');
            background-size: 100% auto;
            width: 100%;
            content: '';
            aspect-ratio: 440 / 150;
        }
    }   
}
