.block-accordeon {
    --color-title-accordeon: #{color(brown)};
    --color-title-shadow-accordeon: #{color(brown)};
    --dropdown-background-color: #{color(white)};
    --dropdown-content-color: #{color(brown)};
    --dropdown-icon-color: #{color(brown50)};
    --dropdown-icon-color2: #{color(brown)};
    --dropdown-icon-color3: #{color(white)};

    position: relative;
    background-color: color(light-brown);
    background-repeat: repeat;
    background-size: 800px;
    overflow-x: clip;
    overflow-y: visible;

    &.has-nuage-bottom {
        padding-bottom: 0 !important;

        &::after {
            display: flex;
            position: relative;
            top: 2px;
            z-index: 3;
            background-image: url($path-webp + 'part-nuage.webp');
            background-size: contain;
            margin-top: 50px;
            width: 100%;
            content: '';
            aspect-ratio: 2160 / 278;
        }
    }

    &.has-transparent-bg-color {
        --dropdown-content-color: #{color(white)};
        --dropdown-icon-color: #{color(white50)};
        --dropdown-icon-color2: #{color(white)};

        background-color: transparent;
        padding-top: 15px;

        .separation-wave {
            display: none;
        }

        &.has-blue-main-color {
            --color-title-accordeon: #{color(blue)};
            --color-title-shadow-accordeon: #{color(text-shadow-blue)};
            --dropdown-background-color: #{color(blue)};
            --dropdown-icon-color3: #{color(blue)};
        }

        &.has-pink-main-color {
            --color-title-accordeon: #{color(pink-title)};
            --color-title-shadow-accordeon: #{color(text-shadow-pink)};
            --dropdown-background-color: #{color(primary)};
            --dropdown-icon-color3: #{color(primary)};
        }
    }

    &.has-decoration {
        &:not(.has-nuage-bottom) {
            &:not(.has-transparent-bg-color) {
                padding-bottom: 100px;

                @include min(breakpoint(md)) {
                    padding-bottom: 230px;
                }

            
                .inner-content-container {
                    padding-bottom: 30px;

                    @include min(breakpoint(md)) {
                        padding-bottom: 90px;
                    }
                }
            }
        }
    }

    .container {
        position: relative;
    }

    .inner-content-container {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        margin: 0 auto;
        max-width: 1020px;
        gap: 30px;

        @include min(breakpoint(md)) {
            gap: 40px;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include min(breakpoint(md)) {
            gap: 20px;
        }

        .title,
        .text {
            text-align: center;
        }

        .title {
            color: var(--color-title-accordeon);
        }
    }

    .wrapper-buttons {
        align-items: center;
        justify-content: flex-end;
    }

    .accordeons-list {
        @extend %reset-ul;

        .accordeons-item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .dropdown {
                background-color: var(--dropdown-background-color);
                border-radius: 50px;
                padding: 20px 40px;

                &[open] {
                    .dropdown-title {
                        .dropdown-icon {
                            &::after {
                                transform: translate(-50%, -50%) rotate(0deg);
                            }
                        }
                    }
                }

                .dropdown-title {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    position: relative;
                    cursor: pointer;
                    padding-right: 42px;
                    color: var(--dropdown-content-color);
                    gap: 25px;

                    @include min(breakpoint(md)) {
                        padding-right: 60px;
                    }

                    &::-webkit-details-marker {
                        display: none;
                    }

                    .title {
                        font-family: $main-font-bold;
                    }

                    .dropdown-icon {
                        display: flex;
                        flex-shrink: 0;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: -4px;
                        right: 0;
                        background-color: var(--dropdown-icon-color);
                        border-radius: 100px;
                        width: 32px;
                        height: 32px;

                        @include min(breakpoint(md)) {
                            top: -10px;
                            width: 50px;
                            height: 50px;
                        }

                        .dropdown-icon-inner {
                            background-color: var(--dropdown-icon-color2);
                            border-radius: 100px;
                            width: 22px;
                            height: 22px;

                            @include min(breakpoint(md)) {
                                width: 40px;
                                height: 40px;
                            }
                        }

                        &::after,
                        &::before {
                            @include absolute-center;

                            transform-origin: center;
                            transition: transform $regular-transition;
                            background-color: var(--dropdown-icon-color3);
                            border-radius: 10px;
                            width: 40%;
                            height: 5px;
                            content: '';
                        }

                        &::after {
                            transform: translate(-50%, -50%) rotate(90deg);
                        }
                    }
                }

                .dropdown-content {
                    padding-top: 17px;
                    font-size: rem(18px);
                    font-weight: 300;
                    color: var(--dropdown-content-color);
                    
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .decorations {
        &.decoration-type-1 {
            .renard-faq {
                display: none;
                position: absolute;
                bottom: -80px;
                left: 50px;
                background-image: url($path-webp + 'faq-renard-interrogation.webp');
                background-repeat: no-repeat;
                background-size: contain;
                width: 230px;
                aspect-ratio: 470 / 256;

                @include min(breakpoint(md)) {
                    display: flex;
                }

                @include min(breakpoint(lg)) {
                    bottom: -120px;
                    width: 300px;
                }
            }

            .interrogation {
                position: absolute;
                right: -30px;
                bottom: -90px;
                background-image: url($path-webp + 'point-interogation.webp');
                background-repeat: no-repeat;
                background-size: contain;
                width: 200px;
                aspect-ratio: 550 / 441;

                @include min(breakpoint(md)) {
                    display: flex;
                }

                @include min(breakpoint(lg)) {
                    right: -100px;
                    bottom: -200px;
                    width: 370px;
                }
            }
        }

        &.decoration-type-2 {
            .interrogation {
                position: absolute;
                top: -48px;
                left: -20px;
                background-image: url($path-webp + 'point-interogation-bleu.webp');
                background-repeat: no-repeat;
                background-size: contain;
                width: 140px;
                aspect-ratio: 550 / 441;

                @include min(breakpoint(md)) {
                    display: flex;
                }

                @include min(breakpoint(lg)) {
                    top: -80px;
                    left: -50px;
                    width: 300px;
                }
            }
        }

        &.decoration-type-3 {
            .interrogation {
                position: absolute;
                top: -50px;
                left: 30px;
                background-image: url($path-webp + 'point-interogation-rose1.webp');
                background-repeat: no-repeat;
                background-size: contain;
                width: 70px;
                aspect-ratio: 550 / 810;

                @include min(breakpoint(lg)) {
                    top: -70px;
                    left: -40px;
                    width: 170px;
                }
            }

            .interrogation2 {
                display: none;
                position: absolute;
                right: -40px;
                bottom: -50px;
                background-image: url($path-webp + 'point-interogation-rose2.webp');
                background-repeat: no-repeat;
                background-size: contain;
                width: 90px;
                aspect-ratio: 550 / 441;
    
                @include min(breakpoint(lg)) {
                    display: flex;
                    right: -80px;
                    bottom: -80px;
                    width: 200px;
                }
            }
        }
    }
}

.block-carousel-citations + .block-accordeon {
    .separation-wave {
        display: none;
    }
}
