.wysiwyg-content {
    --color-wysiwyg-list: #{color(primary)};
    --color-wysiwyg-list-content-ol: #{color(black)};

    h1 {
        margin-bottom: 80px;

        @include min(breakpoint(md)) {
            margin-bottom: 55px;
        }
    }

    h2 {
        font-size: rem(33px);
        color: color(primary);

        &:not(:first-child) {
            margin-top: 50px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    h3 {
        color: color(blue);

        &:not(:first-child) {
            margin-top: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    h4, h5, h6 {
        &:not(:first-child) {
            margin-top: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }


    p, ul, ol {
        margin: 0;
        font-weight: 300;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        img {
            margin-top: 15px;
            margin-bottom: 25px;
        }

        &:last-child {
            img {
                margin-bottom: 0;
            }
        }
    }

    hr {
        opacity: 0.5;
        border-color: color(grey);

        &:not(:first-child) {
            margin-top: 35px;
        }

        &:not(:last-child) {
            margin-bottom: 35px;
        }
    }

    a {
        color: currentColor;

        &:hover {
            color: color(primary);
            text-decoration: none;
        }
    }

    img {
        border-radius: 15px;
        margin: 32px 0;
        width: 100%;
        height: auto;

        @include min(breakpoint(md)) {
            margin: 45px 0;
        }
    }

    figcaption {
        margin: 32px 0;
        width: 100%;
        height: auto;

        @include min(breakpoint(md)) {
            margin: 45px 0;
        }
    }

    &:not(.custom-list) {
        ul, ol {
            padding-left: 0;

            li {
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }

        ul {
            list-style: none;

            li {
                &::before {
                    display: inline-block;
                    background-color: var(--color-wysiwyg-list);
                    border-radius: 100px;
                    margin-right: 12px;
                    margin-left: 0;
                    width: 8px;
                    height: 8px;
                    content: '';
                }

                ul {
                    margin-top: 5px;
                    padding-left: 20px;

                    li {
                        font-size: rem(14px);

                        &::before {
                            background-color: transparent;
                            border: 1px solid color(black);
                            width: 6px;
                            height: 6px;
                            content: '';
                        }
                    }
                }
            }
        }

        ol {
            list-style-type: none;
            counter-reset: li;
            
            li {
                &:not(:last-child) {
                    margin-bottom: 15px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 20px;
                    }
                }

                &::before {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    margin-left: 0;
                    font-weight: 400;
                    color: color(primary);
                    content: counter(li);
                    counter-increment: li;

                    @include min(breakpoint(md)) {
                        font-size: rem(20px);
                    }
                }

                ol {
                    margin-top: 5px;

                    li {
                        font-size: rem(14px);

                        &::before {
                            background-color: transparent;
                            color: color(primary);
                        }
                    }
                }
            }
        }
    }
}
