.anim-element {
    transition: opacity 0.8s ease-out;
    opacity: 0;

    &.appear {
        opacity: 1;
    }

    &.translate-y {
        transform: translateY(30px);
        transition: opacity 0.55s ease-out, transform 0.55s ease-out;

        &.appear {
            /* transform: translateY(0); */
            transform: none;
        }
    }

    &.translate-y-r {
        transform: translateY(-30px);
        transition: opacity 0.55s ease-out, transform 0.55s ease-out;

        &.appear {
            /* transform: translateY(0); */
            transform: none;
        }
    }

    &.translate-x {
        transform: translateX(-30px);
        transition: opacity 0.55s ease-out 0.2s, transform 0.55s ease-out 0.2s;

        &.appear {
            /* transform: translateX(0); */
            transform: none;
        }
    }

    &.translate-x-r {
        transform: translateX(30px);
        transition: opacity 0.55s ease-out 0.2s, transform 0.55s ease-out 0.2s;

        &.appear {
            /* transform: translateX(0); */
            transform: none;
        }
    }

    // transition duration

    &.t150 {
        transition-duration: 0.15s !important;
    }

    &.t250 {
        transition-duration: 0.25s !important;
    }

    &.t350 {
        transition-duration: 0.35s !important;
    }

    &.t600 {
        transition-duration: 0.6s !important;
    }

}

// transition delay
@for $i from 1 through 10 {
    .delay-#{$i} {
        transition-delay: (0.25s * $i) !important;
    }
}
