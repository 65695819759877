.woocommerce-notices-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 30px;
    bottom: 20px;
    z-index: 100;
    max-width: calc(100% - 50px);
    gap: 20px;

    .woocommerce-notif {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        transition: opacity $regular-transition;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        background-color: color(white);
        border: 0;
        border-radius: 20px;
        margin: 0;
        color: color(brown);
        gap: 20px;

        &::after {
            display: none;
        }

        .button {
            --btn-color: #{color(white)};
            --btn-background-color: #{color(blue)};
            --btn-hover-color: #{color(white)};
            --btn-background-hover-color: #{color(primary)};

            @extend %btn;

            border-radius: $button-radius;
            padding: 5px 20px;
            min-height: 40px;
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .woocommerce-error {
        background-color: color(red-alert);
        color: color(white);

        &::before {
            color: color(white);
        }
    }

    .woocommerce-message {
        &::before {
            top: 10px;
            left: 20px;
            font-size: rem(20px);
            color: color(primary);
        }
    }

    .close-button {
        @extend %reset-button;

        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -15px;
        right: -15px;
        transition: background-color $regular-transition;
        background-color: color(primary);
        border: 2px solid color(white);
        border-radius: 100px;
        cursor: pointer;
        width: 30px;
        height: 30px;

        &:hover {
            background-color: color(blue);
        }

        svg {
            width: 50%;

            path {
                fill: color(white);
            }
        }
    }
}
