.block-formulaire {
    .inner-content-container {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include min(breakpoint(md)) {
            gap: 40px;
        }

        .wrapper-content {
            text-align: center;

            .title {
                &:not(:last-child) {
                    margin-bottom: 30px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 40px;
                    }
                }

                &.text-center {
                    text-align: center;
                }
            }
        }

        .wrapper-formulaire {
            margin: 0 auto;
            width: 100%;
            max-width: $container-width-medium;
        }
    }
}
