.block-push-pleine-largeur {
    position: relative;
    background-color: color(primary);
    padding: 40px 0;
    overflow: hidden;

    @include min(breakpoint(md)) {
        padding: 75px 0;
    }

    .inner-content-container {
        text-align: center;

        @include min(breakpoint(md)) {
            margin: 0 auto;
            padding: 0;
            max-width: 720px;
        }

        .title,
        .wrapper-buttons {
            position: relative;
            z-index: 2;
        }

        .title {
            color: color(white);
            text-shadow: 1px 2px color(text-shadow-blue);

            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        .wrapper-buttons {
            align-items: initial;
            justify-content: center;
        }
    }
}
