.block-carousel-categories {
    position: relative;
    overflow: hidden;

    .block-inner-content-container {
        & > .title {
            margin-bottom: 40px;
            color: color(white);
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }
        }
    }

    .wrapper-carousel {
        position: relative;
    }

    .carousel-categories {
        padding: 0;
        width: 100%;

        @include min(breakpoint(md)) {
            width: calc(100% - 150px);
        }

        .swiper-wrapper {
            height: auto;

            @include min(breakpoint(md)) {
                justify-content: center;
            }
        }

        .categorie {
            display: flex;
            position: relative;
            border-radius: 300px;
            width: 100%;
            overflow: hidden;
            text-decoration: none;

            @include min(breakpoint(sm)) {
                width: calc(50% - 15px);
            }

            @include min(breakpoint(md)) {
                width: calc(33% - 17px);
            }

            &:hover {
                .categorie-title {
                    opacity: 1;
                }
            }

            @media (hover: none) {
                .categorie-title {
                    opacity: 1 !important;
                }
            }

            .categorie-image {
                display: flex;
                width: 100%;
                aspect-ratio: 1/1;
            }

            .categorie-title {
                @include absolute-center-full;

                display: flex;
                align-items: center;
                justify-content: center;
                transition: opacity $regular-transition;
                opacity: 0;
                

                .title {
                    position: relative;
                    z-index: 1;
                    font-family: $subtitle-font;
                    font-size: rem(30px);
                    color: color(white);
                    text-align: center;
                }
               
                &::after {
                    @include absolute-center-full;

                    opacity: 0.5;
                    background-color: color(light-blue);
                    border-radius: 300px;
                    width: 90%;
                    height: 90%;
                    content: '';

                    @include min(breakpoint(md)) {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .carousel-categories-pagination {
        display: block;

        @include min(breakpoint(md)) {
            display: none;
        }
    }

    .carousel-categories-navigation {
        display: none;

        @include min(breakpoint(md)) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            transform: translateY(-50%);
            width: 100%;
            pointer-events: none;
            gap: 10px;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: background-color $regular-transition;
            background-color: color(white50);
            border: 0;
            border-radius: 150px;
            cursor: pointer;
            width: 60px;
            height: 60px;
            text-indent: -9999px;
            pointer-events: auto;
            
            &::before {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: width $regular-transition, height $regular-transition;
                background-color: color(white80);
                border-radius: 150px;
                width: calc(100% - 15px);
                height: calc(100% - 15px);
                content: '';
            }

            &::after {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-60%, -50%) rotate(-45deg);
                border-right: 4px solid color(dark-blue);
                border-bottom: 4px solid color(dark-blue);
                border-radius: 3px;
                width: 14px;
                height: 14px;
                content: '';
            }

            &.button-prev {
                &::after {
                    transform: translate(-40%, -50%) rotate(-225deg);
                }
            }

            &:hover {
                &::before {
                    width: 100%;
                    height: 100%;
                }
            }

            &.swiper-button-disabled {
                opacity: 0 !important;
            }
        }
    }
}

