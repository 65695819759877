@font-face {
    font-family: 'oliver';
    src: url('../fonts/oliver-regular-webfont.woff2') format('woff2'),
         url('../fonts/oliver-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'borel';
    src: url('../fonts/borel-regular-webfont.woff2') format('woff2'),
         url('../fonts/borel-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'yaahowuregular';
    src: url('../fonts/yaahowu-webfont.woff2') format('woff2'),
         url('../fonts/yaahowu-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'yaahowu_boldregular';
    src: url('../fonts/yaahowu_bold-webfont.woff2') format('woff2'),
         url('../fonts/yaahowu_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

