/// Reset
///
%reset-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

%reset-button {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    font: inherit;
    line-height: normal;
    color: inherit;
    appearance: none;
    appearance: none;
}

.swiper-navigation {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 25px;

    @include min(breakpoint(md)) {
        display: none;
    }

    .button-prev,
    .button-next {
        @extend %reset-button;

        display: flex;
        position: relative;
        cursor: pointer;

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            opacity: 0.5;
            cursor: none;
        }
    }

    .button-next {
        transform: scaleX(-1);
    }
}

iframe {
    border: 0;
}
