.block-securite-acheteur {
    position: relative;
    margin-bottom: 40px;
    overflow-x: clip;
    overflow-y: visible;

    .block-inner-content-container {
        .title {
            position: relative;
            margin: 0 auto 40px;
            width: fit-content;
            color: color(primary);
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 70px;
            }
        }

        .wrapper-steps {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
                gap: 40px;
            }

            .step {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                @include min(breakpoint(sm)) {
                    width: calc(100% / 2 - (40px * 1 / 2));
                }

                @include min(breakpoint(lg)) {
                    width: calc(100% / 4 - (40px * 3 / 4));
                }

                .step-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 30px;
                    width: 140px;
                    height: 105px;
                }

                .step-title {
                    margin-bottom: 10px;
                    font-family: $subtitle-font;
                    font-size: rem(23px);
                    font-weight: 400;
                    line-height: 1.1;
                    letter-spacing: -0.4px;
                    color: color(primary);
                    text-align: center;
                }

                .step-text {
                    font-size: rem(20px);
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
    }
}

