.block-carousel-citations {
    position: relative;
    background-color: color(yellow);
    overflow: hidden;

    .inner-content-container {
        position: relative;

        & > .title {
            position: relative;
            margin: 0 auto 30px;
            width: fit-content;
            color: color(white);
            text-align: center;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }
        }

        .wrapper-carousel {
            position: relative;
        }

        .carousel {
            overflow: visible;

            .carousel-slide {
                display: flex;
                align-items: center;
                height: unset;
            }
        }

        .temoignage {
            position: relative;
            background-color: color(white50);
            border-radius: 50px;
            margin: 0 auto;
            padding: 20px;
            width: 100%;
            max-width: 1020px;
            height: 100%;

            @include min(breakpoint(md)) {
                width: calc(100% - 140px);
                height: unset;
            }

            .image-emoji {
                display: none;
                position: absolute;
                top: -70px;
                right: -60px;
                width: 150px;
                aspect-ratio: 230 / 214;

                @include min(breakpoint(md)) {
                    display: flex;
                }

                @include min(breakpoint(lg)) {
                    width: 190px;
                }
            }

            .temoignage-inner-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: color(white80);
                border-radius: 40px;
                padding: 20px;
                height: 100%;

                @include min(breakpoint(md)) {
                    padding: 35px;
                }
            }

            .temoignage-heading {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                gap: 10px;

                @include min(breakpoint(md)) {
                    flex-direction: row;
                    gap: 25px;
                }

                &.has-image {
                    .author {
                        @include min(breakpoint(lg)) {
                            width: 300px;
                        }
                    }
                }

                .author {
                    font-family: $subtitle-font;
                    font-size: rem(40px);
                    color: color(brown);
                    text-align: center;

                    @include min(breakpoint(md)) {
                        text-align: left;
                    }
                }

                .note {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    @include min(breakpoint(md)) {
                        justify-content: flex-start;
                    }
                    
                    .star {
                        display: flex;
                        background-position: center;
                        background-size: contain;
                        width: 30px;
                        height: 30px;

                        &.star-empty {
                            background-image: url($path-png + 'avis-off.png');
                        }

                        &.star-full {
                            background-image: url($path-png + 'avis-on.png');
                        }
                    }
                }
            }

            .temoignage-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                @include min(breakpoint(md)) {
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 25px;
                }

                .image-produit {
                    display: none;
                    flex-shrink: 0;
                    border-radius: 20px;
                    width: 100%;
                    max-width: 300px;
                    aspect-ratio: 1/1;

                    @include min(breakpoint(lg)) {
                        display: flex;
                    }
                }

                .wrapper-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 10px;

                    @include min(breakpoint(md)) {
                        align-items: flex-start;
                        text-align: left;
                        gap: 30px;
                    }

                    & > .title {
                        font-size: rem(25px);
                        color: color(yellow);

                        @include min(breakpoint(md)) {
                            font-size: rem(35px);
                        }
                    }

                    & > .text {
                        flex-grow: 1;
                        font-size: rem(15px);

                        @include min(breakpoint(md)) {
                            font-size: rem(18px);
                        }
                    }

                    & > .wrapper-buttons {
                        justify-content: flex-end;
                    }
                }
            }
        }

        .carousel-citations-pagination {
            display: block;

            @include min(breakpoint(md)) {
                display: none;
            }
        }

        .carousel-citations-navigation {
            display: none;

            @include min(breakpoint(md)) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                transform: translateY(-50%);
                width: 100%;
                pointer-events: none;
                gap: 10px;
            }

            .button {
                display: block;
                align-items: center;
                justify-content: center;
                position: relative;
                transition: background-color $regular-transition;
                background-color: color(white50);
                border: 0;
                border-radius: 150px;
                cursor: pointer;
                width: 60px;
                height: 60px;
                text-indent: -9999px;
                pointer-events: auto;
                
                &::before {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: width $regular-transition, height $regular-transition;
                    background-color: color(white80);
                    border-radius: 150px;
                    width: calc(100% - 15px);
                    height: calc(100% - 15px);
                    content: '';
                }

                &::after {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-60%, -50%) rotate(-45deg);
                    border-right: 4px solid color(yellow);
                    border-bottom: 4px solid color(yellow);
                    border-radius: 3px;
                    width: 14px;
                    height: 14px;
                    content: '';
                }

                &.button-prev {
                    &::after {
                        transform: translate(-40%, -50%) rotate(-225deg);
                    }
                }

                &:hover {
                    &::before {
                        width: 100%;
                        height: 100%;
                    }
                }

                &.swiper-button-disabled {
                    opacity: 0;
                }
            }
        }
    }
}
