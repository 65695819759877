/// Path to assets
$path-jpg: '../img/jpg/';
$path-png: '../img/png/';
$path-svg: '../img/svg/';
$path-webp: '../img/webp/';

/// Breakpoints
/// @see {function} breakpoint
$breakpoints: (
    xs: 360,
    sm: 576,
    md: 768,
    nav: 940,
    lg: 1040,
    l: 1100,
    xl: 1200,
    xxl: 1440,
);

/// Couleurs
/// @see {function} color
$colors: (
    white: #fff,
    white10: rgba(255, 255, 255, 0.1),
    white20: rgba(255, 255, 255, 0.2),
    white30: rgba(255, 255, 255, 0.3),
    white50: rgba(255, 255, 255, 0.5),
    white80: rgba(255, 255, 255, 0.8),
    black: #000,
    black20: rgba(0, 0, 0, 0.2),
    black50: rgba(0, 0, 0, 0.5),
    grey: #e5e5e5,
    light-grey: #f4f6f8,
    dark-grey:#1d1d1b,
    secondary-dark-grey:#8e9798,
    primary: #f2a59e,
    primary30 : rgba(241, 165, 162, 0.3),
    secondary: #f1c3bf,
    light-blue: #b5e0e4,
    dark-blue: #88cee1,
    blue: #88cee1,
    yellow: #f8c46e,
    dark-beige: #dfd9ce,
    pink-title: #f2a6a3,
    red-alert: #fd6971,
    brown: #917f7f,
    brown50: #917f7f50,
    light-brown: #f6efeb,
    text-shadow-pink: #c97470,
    text-shadow-pink2: #db9a8f,
    text-shadow-brown: #db9a8f,
    text-shadow-blue: #7bb0cf,
    text-shadow-blue2: #3a5d88,
    orange-dino: #ea8d52,
);

/// Font
$base-font-size: 16px;

$main-font: 'yaahowuregular', sans-serif;
$main-font-bold: 'yaahowu_boldregular', sans-serif;
$title-font: 'oliver', sans-serif;
$subtitle-font: 'borel', sans-serif;

/// Container
$container-width: 1260px;
$container-width-large: 1380px;
$container-width-medium: 1020px;
$container-width-small: 840px;
$container-x-padding: 20px;
$container-x-padding-mobile: 30px;
$container-large-x-padding: 20px;

// Spacing
$spacing-top-bottom-desktop: 80px;
$spacing-top-bottom-mobile: 50px;

/// Height
$height-nav-primary: 215px;
$height-nav-primary-sticky: 80px;
$height-nav-primary-mobile: 115px;
$height-nav-primary-mobile-sticky: 55px;
$height-summary-mobile: 72px;

/// Radius
$button-radius: 150px;
$round-radius: 50%;
$border-radius: 15px;

/// Transition
$regular-transition-duration: 300ms;
$regular-transition: $regular-transition-duration ease-out;
$transition: all $regular-transition-duration ease-out;

// Background
$degrade-light-blue: linear-gradient(161.28deg, #fff 0%, #e5f6fc 96.94%);
$degrade-light-light-blue: linear-gradient(90deg, #00a6ff 8.33%, #4df 100%);
