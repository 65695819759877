.header-single-actu {
    position: relative;
    overflow: hidden;

    .inner-content-container {
        position: relative;
        z-index: 1;
    }

    .heading {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include min(breakpoint(md)) {
            flex-direction: row;
            align-items: center;
            gap: 60px;
        }

        .wrapper-content,
        .wrapper-image {
            @include min(breakpoint(md)) {
                width: 50%;
                max-width: 600px;;
            }
        }

        .heading-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            gap: 30px;

            @include min(breakpoint(md)) {
                margin-bottom: 30px;
            }

            .categories-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 15px;
            }

            .info {
                display: flex;
                align-items: center;
                font-size: rem(12px);
                line-height: 1;
                gap: 7px;
            }
        }
    }

    .wrapper-image {
        border-radius: 20px;
        aspect-ratio: 335/220;

        @include min(breakpoint(md)) {
            aspect-ratio: 600/390;
        }
    }
}
