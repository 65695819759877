.block-texte-image {
    position: relative;
    overflow-x: clip;
    overflow-y: visible;

    .container {
        position: relative;
        z-index: 1;

        .inner-content-container {
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            gap: 40px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
            } 

            .images-decoration {
                position: absolute;
        
                &.coeur {
                    display: none;
                    top: -40px;
                    left: 0px;
                    z-index: 2;
                    background-image: url($path-webp + 'picto_coeur_apropos.webp');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 44%;
                    max-width: 130px;
                    aspect-ratio: 130 / 89;
        
                    @include min(breakpoint(md)) {
                        display: flex;
                    }
                }
                
                svg {
                    fill: color(primary);
                    width: 100%;
                    height: auto;
                }
            }

            &.has-expands {
                @include min(breakpoint(md)) {
                    align-items: flex-start;
                }
            }

            &.image-left-position {
                @include min(breakpoint(md)) {
                    flex-direction: row-reverse;

                    .pattern {
                        right: unset !important;
                        left: -535px !important;
                    }
                }
            }

            .text-side {
                @include min(breakpoint(md)) {
                    width: 55%;
                    max-width: 700px;
                }

                .title {
                    margin-bottom: 16px;
                    color: color(primary);
                    text-align: center;

                    @include min(breakpoint(md)) {
                        margin-bottom: 20px;
                    }
                }

                .content-wrapper {
                    font-size: rem(20px);
                    text-align: center;
                }

                .expands-wrapper {
                    @extend %reset-ul;

                    margin-top: 20px;

                    @include min(breakpoint(md)) {
                        margin-top: 30px;
                    }

                    .expands-item {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                        padding-bottom: 15px;

                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
                    }

                    .dropdown {
                        &[open] {
                            .dropdown-title {
                                .dropdown-icon {
                                    &::after {
                                        transform: translate(-50%, -50%) rotate(0deg);
                                    }
                                }
                            }
                        }
        
                        .dropdown-title {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;
                            gap: 25px;
        
                            &::-webkit-details-marker {
                                display: none;
                            }
        
                            .dropdown-title-inner {
                                font-weight: 400;
                            }
        
                            .dropdown-icon {
                                flex-shrink: 0;
                                position: relative;
                                background-color: color(blue);
                                border-radius: 100px;
                                width: 32px;
                                height: 32px;
        
                                @include min(breakpoint(md)) {
                                    width: 38px;
                                    height: 38px;
                                }
        
                                &::after,
                                &::before {
                                    @include absolute-center;

                                    transform-origin: center;
                                    transition: transform $regular-transition;
                                    background-color: color(white);
                                    width: 40%;
                                    height: 1px;
                                    content: '';
                                }

                                &::after {
                                    transform: translate(-50%, -50%) rotate(90deg);
                                }
                            }
                        }
        
                        .dropdown-content {
                            padding-top: 15px;
                        }
                    }
                }

                .wrapper-buttons {
                    align-items: center;
                    justify-content: center;
                    margin-top: 32px;

                    @include min(breakpoint(md)) {
                        margin-top: 40px;
                    }
                }
            }

            .image-side {
                position: relative;

                @include min(breakpoint(md)) {
                    width: 45%;
                    max-width: 530px;
                }

                .wrapper-image {
                    position: relative;
                    z-index: 1;
                    border-radius: 20px;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: unset;
                    }

                    &.wrapper-image-round {
                        border: 30px solid color(primary30);
                        border-radius: 100%;
                    }
                }
            }
        }
    }
}
