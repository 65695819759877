.wp-block-woocommerce-cart {
    position: relative;
    z-index: 3;
    /* background-image: url($path-png + 'Panier_ALL.png');
    background-position: center top -310px;
    background-repeat: no-repeat;
    background-size: 1440px auto; */
    margin: 50px auto 0;
    padding: 0 $container-x-padding;
    width: 100%;
    max-width: calc($container-width + ($container-x-padding * 2));
    // height: 5000px;

    .wc-block-components-sidebar-layout {
        align-items: flex-start;

        @include max(breakpoint(md)) {
            flex-direction: column;
        }
    }

    // PANIER PLEIN
    .wp-block-woocommerce-filled-cart-block {

        /// ASIDE
        .wp-block-woocommerce-cart-totals-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: color(blue);
            background-size: 760px;
            border: 10px solid #d6eefb;
            border-radius: 60px;
            padding: 20px !important;
            color: color(white);

            @include max(breakpoint(md)) {
                width: 100%;;
            }

            // TITRE ASIDE
            .wp-block-woocommerce-cart-order-summary-heading-block {
                background-image: url($path-svg + 'coeur-blue.svg');
                background-position: left center;
                background-repeat: no-repeat;
                background-size: 80px;
                margin-bottom: 30px;
                padding: 10px 0 0 90px;
                font-family: $title-font;
                font-size: rem(45px);
                line-height: 1;
                text-align: left;
                text-transform: initial;
            }

            .wc-block-components-totals-wrapper:not(:empty) {
                position: relative;
                border-top: 1px solid color(text-shadow-blue);
                padding: 40px 0 25px;

                &::after {
                    display: flex;
                    position: absolute;
                    top: 0%;
                    right: 0;
                    background-image: url($path-png + 'fanion-panier-dark-blue.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 40%;
                    max-width: 155px;
                    content: '';
                    aspect-ratio: 222/46;
                }
            }

            // CODE PROMO
            .wp-block-woocommerce-cart-order-summary-coupon-form-block {
                border: 0 !important;
                
                &::after {
                    display: none !important;
                }
            }

            .wc-block-components-totals-coupon {
                padding: 0;

                .wc-block-components-totals-coupon-link {
                    &:hover {
                        color: color(text-shadow-blue);
                    }
                }

                .wc-block-components-totals-coupon__input {
                    input {
                        background-color: #eef8fd;
                        border: 0;
                        border-radius: 10px 0 0 10px;
                        color: color(black);

                        &:focus {
                            box-shadow: 0 0 0 1px color(text-shadow-blue);
                        }
                    }
                    
                    label {
                        color: color(text-shadow-blue);
                    }

                    & + button {
                        background-color: color(text-shadow-blue);
                        border: 0;
                        border-radius: 0 10px 10px 0;
                        margin: 0;
                        color: color(white);

                        &:disabled {
                            opacity: 0.5;
                        }

                        &:not(:disabled) {
                            cursor: pointer;
                        }
                    }
                }
            }

            // SOUS-TOTAL / REMISE
            .wc-block-components-totals-item {
                padding: 0;

                .wc-block-components-totals-item__label {
                    font-family: $subtitle-font;
                    font-size: rem(30px);
                }

                .wc-block-components-totals-item__value {
                    display: flex;
                    align-items: center;
                    font-family: $main-font-bold;
                }
            }

            .wc-block-components-totals-discount {
                .wc-block-components-totals-item__value {
                    color: color(dark-blue);
                }

                .wc-block-components-totals-discount__coupon-list {
                    .wc-block-components-chip {
                        background-color: color(text-shadow-blue);
                        border: 0;

                        .wc-block-components-chip__remove {
                            background-color: color(white);
                            cursor: pointer;

                            svg {
                                fill: color(text-shadow-blue);
                            }
                        }
                    }
                }
            } 

            // TOTAL
            .wc-block-components-totals-footer-item {
                .wc-block-components-totals-item__label {
                    font-size: rem(40px);
                }

                .wc-block-components-totals-footer-item-tax-value {
                    font-size: rem(35px);
                }
            }

            // CHECKOUT
            .wp-block-woocommerce-proceed-to-checkout-block {
                margin: 0;

                .wc-block-cart__submit-button {
                    --btn-color: #{color(white)};
                    --btn-hover-color: #{color(white)};
                    --btn-background-color: #{color(text-shadow-blue)};
                    --btn-background-hover-color: #{color(primary)};

                    @extend %btn;

                    display: flex;
                    border: 0;
                    border-radius: $button-radius;
                    margin: 0 auto;
                    padding: 5px 20px;
                    width: fit-content;
                    min-height: 40px;
                    overflow: hidden;
                }
            }

            // BOUTON STICKY MOBILE
            .wc-block-cart__submit-container--sticky {
                background-color: color(blue) !important;
            }
        }
        /// FIN ASIDE

        /// MAIN CART
        .wc-block-cart__main {
            @include max(breakpoint(md)) {
                padding: 0;
                width: 100%;
            }

            .wc-block-cart-items td {
                border-top: 0 !important;
            }

            .wc-block-cart-items {
                display: block;
                border: 0;

                td, th, tbody, thead {
                    display: block;
                }

                .wc-block-cart-items__header {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    width: 100%;

                    th {
                        width: fit-content;
                    }

                    .wc-block-cart-items__header-image {
                        background-image: url($path-png + 'carton-cadeau.png') !important;
                        background-position: left bottom !important;
                        background-repeat: no-repeat !important;
                        background-size: 45px !important;
                        padding-left: 55px;
                        font-family: $title-font;
                        font-size: rem(50px);
                        line-height: 1;
                        color: color(blue);
                        text-transform: initial;

                        @include min(breakpoint(md)) {
                            background-size: 75px !important;
                            padding-left: 85px;
                            font-size: rem(60px);
                        }
                    }

                    .wc-block-cart-items__header-total {
                        display: none;
                        color: color(pink-title);

                        @include min(breakpoint(md)) {
                            display: block;
                        }
                    }
                }

                .wc-block-cart-items__row {
                    display: flex;
                    gap: 25px;
                    flex-direction: column;

                    @include min(breakpoint(sm)) {
                        flex-direction: row;
                    }

                    &:not(:last-child) {
                        position: relative;
                        border-bottom: 1px solid color(blue);
                        margin-bottom: 30px;
                        padding-bottom: 30px;

                        &::after {
                            display: flex;
                            position: absolute;
                            top: 100%;
                            right: 0;
                            background-image: url($path-png + 'fanion-panier-blue.png');
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 20%;
                            content: '';
                            aspect-ratio: 222/46;
                        }
                    }

                    td {
                        margin: 0;
                        padding: 0;
                    }

                    .wc-block-cart-item__image {
                        display: flex;
                        flex-shrink: 0;
                        border-radius: 20px;
                        overflow: hidden;

                        @include min(breakpoint(sm)) {
                            width: 220px;
                            height: 220px;
                        }

                        a {
                            display: flex;
                            width: 100%;
                        }
                    }

                    .wc-block-cart-item__product {
                        flex-grow: 1;

                        .wc-block-cart-item__wrap {
                            .wc-block-components-product-name {
                                margin-bottom: 5px;
                                font-family: $subtitle-font;
                                font-size: rem(25px);
                                line-height: 1;
                                color: color(pink-title);
                                text-decoration: none;
                            }

                            .wc-block-cart-item__prices {
                                margin-bottom: 0px;
                                font-size: rem(20px);
                                color: color(blue);
                            }

                            .wc-block-components-product-metadata {
                                .wc-block-components-product-details {
                                    .wc-block-components-product-details__value {
                                        font-family: $main-font-bold;
                                        color: color(text-shadow-blue);
                                    }
                                }
                            }

                            .wc-block-components-quantity-selector {
                                margin-bottom: 10px;

                                &::after {
                                    border-color: color(blue);
                                }

                                .wc-block-components-quantity-selector__input {
                                    color: color(blue);
                                }

                                .wc-block-components-quantity-selector__button {
                                    background-color: color(blue);
                                    color: color(white);
                                }
                            }

                            .wc-block-cart-item__remove-link {
                                color: color(pink-title);
                            }
                        }
                    }

                    .wc-block-cart-item__total {
                        margin-top: 5px;
                        font-family: $main-font-bold;
                        font-size: rem(22px);
                        color: color(pink-title);
                    }
                }
            }
        }
    }

    // PANIER VIDE
    .wp-block-woocommerce-empty-cart-block {
        margin-bottom: 50px;

        @include min(breakpoint(md)) {
            margin-bottom: 100px;
        }

        .wp-block-heading {
            @extend %h2;

            margin-bottom: 30px;
            color: color(blue);
            text-align: center;

            &.with-empty-cart-icon {
                &::before {
                    background-color: unset;
                    background-image: url($path-png + 'renard-triste.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-bottom: 0;
                    width: 120px;
                    height: 120px;
                    mask-image: unset;

                    @include min(breakpoint(md)) {
                        width: 200px;
                        height: 200px;
                    }
                }
            }
        }

        .wp-block-separator {
            margin: 30px 0;
        }

        .wc-block-grid__products {
            --gap-products: 30px;

            display: flex !important;
            flex-wrap: wrap;
            justify-content: center;
            gap: var(--gap-products);

            @include min(breakpoint(md)) {
                margin-top: 50px;
            }

            .wc-block-grid__product {
                display: flex;
                flex: unset;
                flex-direction: column;
                justify-content: space-between;
                float: unset !important;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
                background-color: color(white);
                border: 0;
                border-radius: 50px;
                margin: 0 !important;
                padding: 0 !important;
                padding-bottom: 20px !important;
                width: 100% !important;
                max-width: 293px;
                overflow: hidden;

                @include min(breakpoint(sm)) {
                    width: calc(100% / 2 - (var(--gap-products) * 1 / 2)) !important;
                }

                @include min(breakpoint(lg)) {
                    width: calc(100% / 4 - (var(--gap-products) * 3 / 4)) !important;
                }

                .wc-block-grid__product-link {
                    text-decoration: none;
                }

                .wc-block-grid__product-image {
                    position: relative;
                    margin: 0 -10px;
                    
                    &::after {
                        display: flex;
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        background-image: url($path-png + 'nuage-carousel.png');
                        background-size: 100% auto;
                        width: 100%;
                        content: '';
                        aspect-ratio: 440 / 150;
                    }
                }
            
                .wc-block-grid__product-title {
                    position: relative;
                    margin-bottom: 10px;
                    padding: 0;
                    font-family: $title-font;
                    font-size: rem(32px) !important;
                    color: color(blue);
                    text-align: center;
                    text-shadow: 2px 2px color(text-shadow-blue);
                }
    
                .price {
                    font-size: rem(28px) !important;
                    color: color(primary) !important;
                    text-align: center;
                }
    
                .wp-block-button {
                    .wp-block-button__link {
                        display: flex;
                        align-items: center;
                        background-color: color(blue);
                        border-radius: 150px;
                        margin: 0 auto;
                        padding: 5px 20px;
                        min-height: 40px;
                        font-size: rem(20px);
                        color: color(white);
        
                        &:hover {
                            background-color: color(primary);
                        }
                    }
                }
    
                .wc-forward {
                    text-align: center;
                    text-decoration: underline;
                }
    
                .wc-block-grid__product-onsale {
                    top: 50px !important;
                    right: -22% !important;
                    transform: rotate(45deg);
                    background-color: color(primary);
                    border: 0;
                    border-radius: 0;
                    margin: 0;
                    padding: 10px;
                    width: 100%;
                    color: color(white);
                }
            }
        }
    }
}


// RADIO INPUT EXPEDITION
.wc-block-components-radio-control {
    .wc-block-components-radio-control__input {
        width: 20px !important;
        min-width: 20px !important;
        height: 20px !important;
        min-height: 20px !important;

        &:checked {
            border: 1px solid color(dark-blue) !important;

            &::before {
                background-color: color(dark-blue) !important;
            }
        }
    }
}

.is-large.wc-block-cart .wc-block-components-sidebar .wc-block-components-shipping-rates-control__package:not(.wc-block-components-panel) {
    padding: 0 !important;
}
