.header-accueil {
    position: relative;
    background-color: color(secondary);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0 !important;
    overflow-x: clip;
    overflow-y: visible;

    &.header-accueil-tree {
        &::before {
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url($path-png + 'header-accueil-arbre-a.png');
            background-position: bottom left;
            background-repeat: no-repeat;
            background-size: contain;
            width: 36%;
            content: '';
            aspect-ratio: 518 / 630;
        }

        &::after {
            display: flex;
            position: absolute;
            right: 0;
            bottom: 20px;
            background-image: url($path-png + 'header-accueil-arbre-b.png');
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: contain;
            width: 45%;
            content: '';
            aspect-ratio: 944/667;
        }
    }

    .header-accueil-top {
        position: relative;
    
        &::after {
            display: flex;
            position: absolute;
            top: -#{calc($height-nav-primary-mobile + 32px)};
            right: 0;
            background-image: url($path-webp + 'part-bleu.webp');
            background-position: top right;
            background-repeat: no-repeat;
            background-size: contain;
            width: 50%;
            max-width: 1008px;
            content: '';
            aspect-ratio: 307/238;

            @include min(breakpoint(md)) {
                top: -#{calc($spacing-top-bottom-desktop + $height-nav-primary - 80px)};
                width: 70%;
            }
        }

        .inner-content-container {
            position: relative;
            z-index: 1;
            padding-top: 30px;

            @include min(breakpoint(md)) {
                display: flex;
                align-items: flex-start;
                padding-top: 0;
            }

            .wrapper-text {
                text-align: center;

                @include min(breakpoint(md)) {
                    padding-top: 40px;
                    width: 57%;
                    text-align: left;
                }

                @include min(breakpoint(lg)) {
                    padding-left: 90px;
                }

                & > .title {
                    color: color(white);
                    text-shadow: 3px 3px color(text-shadow-pink2);
                    
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                & > .text {
                    font-size: rem(28px);
                    line-height: 1.2;
                    color: color(white);
                    text-shadow: 2px 1px color(text-shadow-pink2);

                    @include min(breakpoint(md)) {
                        font-size: rem(44px);
                    }
                }

                .wrapper-buttons {
                    align-items: center;
                    margin-top: 25px;
                }
            }

            .wrapper-image {
                @include min(breakpoint(md)) {
                    margin-top: -80px;
                    width: 43%;
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: unset;
                }
            }
        }
    }

    .header-accueil-bottom {
        position: relative;
        margin-top: 80px;
        padding-bottom: 30px;

        @include min(breakpoint(md)) {
            margin-top: -30px;
            padding-bottom: 0;
        }

        .container {
            max-width: 1080px;
        }

        .wrapper-citation {
            display: flex;
            align-items: center;
            gap: 20px;
            position: relative;
            z-index: 1;

            .image-renard {
                position: absolute;
                top: -40px;
                left: 50%;
                z-index: 1;
                transform: translateX(-50%);
                width: 120px;

                @include min(breakpoint(md)) {
                    position: static;
                    transform: unset;
                    width: 170px;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .citation {
                position: relative;
                background-color: color(white20);
                border-radius: $border-radius;
                padding: 80px 20px 20px;
                font-family: $main-font-bold;
                font-size: rem(23px);
                color: color(white);
                text-align: center;

                @include min(breakpoint(md)) {
                    padding: 20px;
                    max-width: 370px;
                    text-align: left;
                }
            }
        }

        .points-forts {
            @extend %reset-ul;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            margin-top: 30px;
            gap: 10px;

            @include min(breakpoint(md)) {
                flex-direction: row;
            }

            .point-fort {
                display: flex;
                align-items: center;
                width: 100%;
                font-family: $main-font-bold;
                font-size: rem(16px);
                color: color(white);
                gap: 20px;

                @include min(breakpoint(md)) {
                    width: calc(100% / 3);
                    max-width: 320px;
                }

                &.point-fort-0 {
                    @include min(breakpoint(md)) {
                        margin-top: 45px;
                    }
                }

                &.point-fort-2 {
                    @include min(breakpoint(md)) {
                        margin-top: 65px;
                    }
                }

                .number {
                    display: flex;
                    flex-shrink: 0;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    outline: 7px solid color(white20);
                    background-color: color(white);
                    border-radius: 100px;
                    width: 50px;
                    height: 50px;
                    font-size: rem(20px);
                    color: color(primary);
                    text-shadow: none;
                }
            }
        }
    }

    .header-accueil-end {
        display: flex;
        position: relative;
        top: 2px;
        z-index: 3;
        background-image: url($path-webp + 'part-nuage.webp');
        background-size: contain;
        width: 100%;
        content: '';
        aspect-ratio: 2160 / 278;
    }
}
