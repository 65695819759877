.breadcrumb {
    @extend %reset-ul;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 25px;
    font-size: rem(12px);
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
    gap: 10px;

    @include min(breakpoint(md)) {
        font-size: rem(16px);
    }

    .breadcrumb-item {
        display: flex;
        align-items: center;
        opacity: 1;
        gap: 10px;

        &:not(:last-child) {
            &::after {
                display: inline-flex;
                flex-shrink: 0;
                transform: rotate(-45deg);
                transition: $transition;
                border-right: 1px solid currentColor;
                border-bottom: 1px solid currentColor;
                width: 4px;
                height: 4px;
                content: '';
            }
        }

        a {
            text-decoration: none;

            &:hover {
                opacity: 1;
                text-decoration: underline;
            }
        }
    }
}
